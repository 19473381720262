export const skyIconOptions = {
  Ensolarado: "sol",
  Nublado: "nublado",
  Chuva: "chuva",
  Tempestade: "tempestade",
  "Céu Limpo": "lua",
};

export const skyConditionOptions = [
  {
    value: "Ensolarado",
    label: "forecasts.labels.sunny",
    icon: "sol",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Nublado",
    label: "forecasts.labels.cloudy",
    icon: "nublado",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Chuva",
    label: "forecasts.labels.rain",
    icon: "chuva",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Tempestade",
    label: "forecasts.labels.storm",
    icon: "tempestade",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Céu Limpo",
    label: "forecasts.labels.clearSky",
    icon: "lua",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
];

export const windConditionOptions = [
  {
    value: "Fraco",
    label: "forecasts.labels.weak",
    icon: "weak",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Moderado",
    label: "forecasts.labels.moderate",
    icon: "weak",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Forte",
    label: "forecasts.labels.strong",
    icon: "strong",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
  {
    value: "Muito Forte",
    label: "forecasts.labels.veryStrong",
    icon: "very_strong",
    uncheckedStyle: "c-radio-button",
    checkedStyle: "c-radio-button c-radio-button--checked",
  },
];

export const windConditionSelector = (wind) => {
  const windCondition = {
    Fraco: "forecasts.labels.weak",
    Moderado: "forecasts.labels.moderate",
    Forte: "forecasts.labels.strong",
    "Muito Forte": "forecasts.labels.veryStrong",
  };

  return windCondition[wind];
};

export const skyConditionSelector = (sky) => {
  const skyCondition = {
    Ensolarado: "forecasts.labels.sunny",
    Nublado: "forecasts.labels.cloudy",
    Chuva: "forecasts.labels.rain",
    Tempestade: "forecasts.labels.storm",
    "Céu Limpo": "forecasts.labels.clearSky",
  };

  return skyCondition[sky];
};
