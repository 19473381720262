import styled from 'styled-components';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

export const StyledButton = styled(Button)`
    width: 15.1rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    background-color: #ffffff;
    border: 1px solid #4120CE;
    border-radius: 4px;
    color: #4120CE;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 100%;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    text-transform: none;
`;

export const StyledMenuItem = styled(MenuItem)`
    font-family: "Inter", sans-serif;
    line-height: 160%;
    padding: 11px 2px 6px 15px;
    font-size: 1.4rem;
    color: #292929;
    font-weight: regular;
    border-top: none;
    border-right: 1px solid #E0E0E0;  
    border-left: 1px solid #E0E0E0;  
    border-bottom: 1px solid #E0E0E0;
    &:hover {
        background-color: #EDEAFA;
    }
`

