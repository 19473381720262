import { SET_ERROR_STATUS, SET_ERROR_TYPE } from '../actions/errors'

const initialState = {
	hasError: false,
	errorType: null,
}

export default function error(state=initialState,action) {
	switch(action.type) {
		case SET_ERROR_STATUS:
			return {
				...state,
				hasError: action.hasError
			}
		case SET_ERROR_TYPE:
			return {
				...state,
				errorType: action.errorType
			}
		default:
			return state
	}
}