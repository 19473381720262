import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import IconSelector from "../IconSelector";
import { getSelectOptions } from "../../utils/dataAPI";

export const optionSelector = (option) => {
  const optionMap = {
    Administrador: "users.profiles.adm",
    Operador: "users.profiles.operator",
    "Emissão alerta": "users.profiles.alertIssuance",
    Cadastro: "users.profiles.register",
    "Relatório de chuva": "users.profiles.rainReport",
  };

  return optionMap[option] || option;
};

function SelectInput({ params }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.uiActions.theme);
  const { id, formProps, formType, validations, optionsUrl, multiple } = params;
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    getSelectOptions(optionsUrl, dispatch).then((response) => {
      setSelectOptions(response.data);
    });
  }, []);

  const handleSelectChange = (event) => {
    const selectedValues = event.target.value;

    if (selectedValues.length) {
      const filteredValues = selectedValues.filter((value) => value !== "none");
      formProps.handleChange({
        target: {
          name: params.id,
          value: filteredValues,
        },
      });
    }
  };

  return (
    <>
      <label className="label" htmlFor={id}>
        {t(`${formType}.fields.${id}`)}
        {validations.filter((rule) => rule.type === "required").length > 0 &&
          "*"}
      </label>
      <Select
        id={id}
        name={id}
        multiple={multiple}
        className={
          formProps.errors[params.id] && formProps.touched[params.id]
            ? "select--error"
            : "select"
        }
        IconComponent={(props) => (
          <IconSelector
            svg="ChevronDown"
            classname={`icon--arrow-select icon--arrow-select-form ${props.className}`}
          />
        )}
        MenuProps={{
          classes: {
            paper: "menu menu--form",
            root: `theme--${theme}`,
          },
        }}
        value={
          Array.isArray(formProps.values[params.id])
            ? formProps.values[params.id]
            : [formProps.values[params.id]]
        }
        onChange={handleSelectChange}
        onBlur={formProps.handleBlur}
        disabled={formProps.isSubmitting}
      >
        <MenuItem className="menu-item--hidden" disabled value="none">
          {t(`${formType}.fieldPlaceholder.${id}`)}
        </MenuItem>
        {selectOptions.length !== 0 &&
          selectOptions.map((option) => (
            <MenuItem className="menu-item" key={option.id} value={option.id}>
              {t(optionSelector(option.name))}
            </MenuItem>
          ))}
      </Select>
      <div
        className={
          formProps.errors[params.id] && formProps.touched[params.id]
            ? "c-error-text"
            : "c-error-text c-error-text--hidden"
        }
      >
        <IconSelector svg={"ErrorWarningLine"} classname={"icon--warning"} />
        <span className="error-text">{formProps.errors[params.id]}</span>
      </div>
    </>
  );
}

export default SelectInput;
