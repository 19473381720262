import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import IconSelector from "../../components/IconSelector";
import { iconConfig } from "../icon-config";
import i18n from "../../i18n/i18n";
import { ModalDialog } from "../../components/ModalDialog/ModalDialog";
import {
  skyConditionSelector,
  windConditionSelector,
} from "../../components/forms/ForecastsForm/constants";
import { formatDateHour } from "../../utils/formatDate";

export const forecastTableConfig = {
  columns: [
    {
      id: "icon",
      cell: (row) => (
        <Avatar className="icon--table-avatar">
          <IconSelector svg={iconConfig[row.icon.toLowerCase()]} />
        </Avatar>
      ),
      sortable: false,
      searchable: false,
      width: "6.2rem",
      linkedOmit: "teste",
    },
    {
      id: "create_date",
      name: i18n.t("forecasts.tableColumns.create_date"),
      selector: (row) => formatDateHour(dayjs(row.create_date)),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "21rem",
    },
    {
      id: "alias",
      name: i18n.t("forecasts.tableColumns.alias"),
      selector: (row) => row.alias,
      sortable: true,
      searchable: true,
      linkedOmit: null,
    },
    {
      id: "sky_condition",
      name: i18n.t("forecasts.tableColumns.sky_condition"),
      selector: (row) => i18n.t(skyConditionSelector(row.sky_condition)),
      sortable: true,
      searchable: false,
      linkedOmit: null,
    },
    {
      id: "wind_condition",
      name: i18n.t("forecasts.tableColumns.wind_condition"),
      selector: (row) => i18n.t(windConditionSelector(row.wind_condition)),
      sortable: true,
      searchable: false,
      linkedOmit: null,
    },
    {
      id: "humidity",
      name: i18n.t("forecasts.tableColumns.humidity"),
      selector: (row) =>
        i18n.t("forecasts.tableColumns.humidityLevel", {
          min_value: row.humidity.min_humidity,
          max_value: row.humidity.max_humidity,
        }),
      sortable: false,
      searchable: false,
      linkedOmit: null,
    },
    {
      id: "Actions",
      name: i18n.t("forecasts.tableColumns.Actions"),
      selector: (row) => <ModalDialog id={row.id} />,
      sortable: false,
      searchable: false,
      linkedOmit: null,
      width: "21.3rem",
    },
  ],
  hiddenColumns: [],
  initialSort: "create_date",
  initialOrder: "desc",
  permission: "weather",
  hasSelectableRows: false,
  requestUrls: {
    get: "api/weather/forecast",
  },
};
