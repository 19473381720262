import * as FileSaver from 'file-saver'

export const exportCSV = (data,columns,filename) => {
	let filteredHeaders = columns.filter(column => column.name && !column.omit)
	let headers = filteredHeaders.map(column => column.name)
	let keys = filteredHeaders.map(column => column.id)
	let rows = data.map(item => {
		let resultItem = {}

		//Formats the data for the csv file//
		Object.keys(item).forEach(key => {
			let columnSelector = filteredHeaders.filter(column =>  column.id === key)
			if (columnSelector.length !== 0) {
				if (key === 'status') {
					resultItem[key] = columnSelector[0].selector(item).props.children
				} else {
					resultItem[key] = columnSelector[0].selector(item)
				}
			}
		})
		return resultItem
	})

	let result = '';
	const columnDelimiter = ';';
  const lineDelimiter = '\n';
  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  rows.forEach(item => {
  	let ctr = 0;
  	keys.forEach(key => {
  		if (ctr > 0) result += columnDelimiter;

  		result += item[key];
  		ctr++;
  	});
  	result += lineDelimiter;
  })
  let blob = new Blob([result], {type: 'text/csv;charset=utf-8'})
  FileSaver.saveAs(blob, `${filename}.csv`)
}