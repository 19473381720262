export const SET_ERROR_STATUS = 'SET_ERROR_STATUS'
export const SET_ERROR_TYPE = 'SET_ERROR_TYPE'

export function setErrorStatus(bool) {
	return {
		type: SET_ERROR_STATUS,
		hasError: bool
	}
}

export function setErrorType(type) {
	return {
		type: SET_ERROR_TYPE,
		errorType: type
	}
}