import { Cookies } from 'react-cookie';

import { setUser } from '../redux/actions/user';

export const requestLogin = (username,password,captcha) => {
	const loginHeaders = new Headers();
	const authString = `${username}:${password}`;

	loginHeaders.set('Authorization', `Basic ${btoa(authString)}`)
	loginHeaders.set('g-recaptcha-response', `${captcha}`)
	loginHeaders.set('Access-Control-Allow-Origin', '*')

	return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/user/login`, {
		method: 'POST',
		credentials: 'omit',
		headers: loginHeaders
	})
}

function getServiceUrl() {
	return process.env.REACT_APP_WAC_SERVICE_URL || process.env.REACT_APP_SERVICE_URL
}

export const requestRecovery = (email) => {
	const headers = new Headers();
	const options = {
		body: JSON.stringify({
			username: email,
			project: 'Cittua',
		})
	}

	headers.set('Content-Type', 'application/json')
	headers.set('Access-Control-Allow-Origin', '*')

	return fetch(`${getServiceUrl()}/api/credential/recover-password`, {
		method: 'POST',
		headers: headers,
		...options
	}).then((response) => response.json())
}

export const requestVerifyPin = (params) => {
	const { pin, userID, type } = params
	const headers = new Headers();
	const options = {
		body: JSON.stringify({
			...(type === 'recovery') && ({username: userID}),
			...(type === 'activate') && ({user_token: userID}),
			pin: pin,
		})
	}

	headers.set('Content-Type', 'application/json')
	headers.set('Access-Control-Allow-Origin', '*')

	const requestUrl = {
		recovery: 'verify-pin',
		activate: 'verify-pin-with-token'
	}

	return fetch(`${getServiceUrl()}/api/credential/${requestUrl[type]}`, {
		method: 'POST',
		headers: headers,
		...options
	}).then((response) => response.json())
}

export const sendPassword = (params) => {
	const { password, userID, pin, type } = params
	const headers = new Headers();
	const options = {
		body: JSON.stringify({
			password: password,
			...(type === 'recovery') && {username: userID},
			...(type === 'activate') && {user_token: userID},
			pin: pin,
		})
	}

	headers.set('Content-Type', 'application/json')
	headers.set('Access-Control-Allow-Origin', '*')

	const requestUrl = {
		recovery: 'credential/change-password',
		activate: 'cittua/users/first-activate',
	}

	return fetch(`${getServiceUrl()}/api/${requestUrl[type]}`, {
		method: 'POST',
		headers: headers,
		...options,
	}).then((response) => response.json())
}

export const refreshToken = (token) => {
	const headers = new Headers()

	headers.set('Content-Type', 'application/json');
	headers.set('Access-Control-Allow-Origin', '*');
	headers.set('Authorization', `Bearer ${token}`);

	return fetch(`${getServiceUrl()}/api/credential/refresh`, {
		method: 'POST',
		headers: headers,
	}).then(response => response.json())
}

export const requestLogout = (dispatch, navigate) => {
	const cookies = new Cookies()

	cookies.remove('user', {path: '/', domain: window.location.hostname})
	cookies.remove('tenant', {path: '/', domain: window.location.hostname})
	dispatch(setUser({}))
	navigate('/login')
}