import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";
import PermissionsControl from "../PermissionsControl";
import { usersTableConfig } from "../../data/usersTableConfig/usersTableConfig";
import { HeaderUserTable } from "./header-user-table/HeaderUserTable";

function UserTable() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("users.pageTitle.table");
  }, [t]);
  
  const buildRequestParams = (data, search, tableParams) => {
    
    const formatSearch = (searchItem) => {
      if (searchItem.field === "creation_date") {
        searchItem.value = searchItem.value.replace(/\//g, '-');
      }
      return searchItem;
    };
  
   
    const formattedSearch = search.map(formatSearch);
  
    let requestParams = {
      options: {
        page: tableParams.page,
        perPage: tableParams.perPage,
        sortBy: tableParams.sortBy,
        order: tableParams.order,
        search: formattedSearch.length > 0 ? formattedSearch : [],
      },
      requestUrl: usersTableConfig.requestUrls.get,
      tableType: "users",
      ...(data.signal && { signal: data.signal }),
    };
  
    return requestParams;
  };
  

  return (
    <div  className="c-main-content c-main-content--table">
      <PermissionsControl
        role={usersTableConfig.permission}
        type="componentPage"
        action="read"
      >
        <CustomTable
          params={{
            tableType: "users",
            configTable: usersTableConfig,
            HeaderRoot: HeaderUserTable,
            buildRequestParams: buildRequestParams,
          }}
        />
      </PermissionsControl>
    </div>
  );
}

export default UserTable;
