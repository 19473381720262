import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Skeleton from '@mui/material/Skeleton'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import IconSelector from './IconSelector'
import TextInput from './form_elements/TextInput'
import BtnLoadingText from './BtnLoadingText'
import { uploadAvatar, removeAvatar, updateActiveUser } from '../utils/dataAPI'
import { handleGetActiveUser } from '../redux/actions/user'
import { formatDateWithoutHour } from '../utils/formatDate'
import dayjs  from 'dayjs';
import { optionSelector } from './form_elements/SelectInput'

function UserProfile() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user.data)
	const [profileImg, setProfileImg] = useState(user.photo)
	const [showForm, setShowForm] = useState(false)
	const [loading, setLoading] = useState(true)
	const [openToast, setOpenToast] = useState(false)
	const [toastMsg, setToastMsg] = useState('')

	const handleImgUpload = async (event) => {
		setLoading(true)

		if (event.target.files[0]) {
			if (event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg') {
				let reader = new FileReader();
				reader.onloadend = async function() {
				  try {
				  	const response = await uploadAvatar(reader.result.split(',')[1], dispatch)
				  	if (response && response.success) {
				  		setProfileImg(reader.result)
				  		dispatch(handleGetActiveUser())
				  	} else {
				  		setToastMsg(response.message)
				  		setOpenToast(true)
				  	}
				  	setLoading(false)
				  } catch (error) {
				  	setToastMsg('Ocorreu um erro inesperado e a operação não foi realizada')
				  	setOpenToast(true)
				  	setLoading(false)
				  }
				}
				reader.readAsDataURL(event.target.files[0])
			} else {
				setLoading(false)
			}
		} else {
			setLoading(false)
		}
	}

	const handleRemovePhoto = async () => {
		setLoading(true)
		
		const response = await removeAvatar(dispatch)

		if (response && response.success) {
			dispatch(handleGetActiveUser())
			setProfileImg(null)
		} else {
			setToastMsg(response.message)
			setOpenToast(true)
		}
		setLoading(false)
	}

	const handleCloseToast = () => {
		setOpenToast(false)
	}

	const onUpdateInfo = (event) => {
		event.stopPropagation()
		setShowForm(true)
	}

	const cancelUpdate = () => {
		setShowForm(false)
	}

	function getInitialValues(item) {
		const initialValues = {
			first_name: item?.first_name || '',
			last_name: item?.last_name || '',
			social_name: item?.social_name || '',
			birth_date: item.birth_date || '',
		}

		return initialValues
	}

	function formValidation(values) {
		let errors = {}
		return errors
	}

	function formSubmit(values,actions) {
		setLoading(true)

		let updatedItem = {...values}
		for (let input in values) {
			if (values[input] === '' || values[input] === 'none') {
				updatedItem[input] = null
			}
		}

		const arg = {
			form: {
				id: user.id,
				...updatedItem,
			},
		}

		updateActiveUser(arg, dispatch).then(async response => {
			if (response?.success) {
				dispatch(handleGetActiveUser())
				actions.setSubmitting(false)
				setLoading(false)
				cancelUpdate()
			} else {
				actions.setSubmitting(false)
			}
			setLoading(false)
		})
	}

	useEffect(() => {
		document.title = t('userProfile.pageTitle')
	})

	useEffect(() => {
		if (Object.keys(user).length !== 0) {
			setProfileImg(user.photo)
			setLoading(false)
		}

	}, [user, user.photo])

	const translateOptions = (profiles) => {
		const translatedOptions = profiles.map(optionSelector).map(option => t(option));
		return translatedOptions.length > 1 ? translatedOptions.join(", ") : translatedOptions[0] ;
	}
	
	return (
		<div className='c-main-content'>
			<Snackbar
				open={openToast}
				autoHideDuration={5000}
				onClose={handleCloseToast}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<SnackbarContent
					message={
						<div className='c-toast-message'>
							<Avatar className='c-toast-icon'>
								<IconSelector svg='Warning' classname='icon--toast-warning'/>
							</Avatar>
							<span className='toast-message'>{toastMsg}</span>
						</div>
					}
					action={
						<Button className='btn-modal' onClick={handleCloseToast}><IconSelector svg='CloseLine' classname={'icon--close'}/></Button>
					}
					className='toast'
				>
				</SnackbarContent>
			</Snackbar>
			<Accordion
				defaultExpanded
				classes={{root: 'c-profile-section'}}
			>
				<AccordionSummary
					expandIcon={<IconSelector svg='ChevronDown' classname={'icon--accordion-chevron'}/>}
					classes={{root: 'profile-summary', content: 'profile-summary-content'}}
				>
					<span className='profile-section-title'>{t('userProfile.personalData')}</span>
					{!showForm && <Button className='btn-secondary btn-secondary--medium btn-secondary--margin-left-auto btn-secondary--margin-right-18' onClick={onUpdateInfo}>{t('userProfile.updateInfoBtn')}</Button>}
				</AccordionSummary>
				<AccordionDetails
					classes={{
						root: 'c-profile-details c-profile-details--primary'
					}}
				>
					{
						showForm ? 
						(
							<Formik
								initialValues = {getInitialValues(user)}
								validate={values => formValidation(values)}
								onSubmit={(values,actions) => formSubmit(values,actions) }
								enableReinitialize={true}
							>
								{formProps => 
									<form
										onSubmit={formProps.handleSubmit}
										className='user-profile-form'
									>
										<div className='c-input'>
											<TextInput 
												params={{
													id: 'first_name',
													editDisabled: false,
													formProps,
													formType: 'userProfile',
													validations: [
														{
															type: 'required',
															value: true,
														},
														{
															type: 'minLength',
															value: 3,
														},
													]
												}}
											/>
										</div>
										<div className='c-input'>
											<TextInput 
												params={{
													id: 'last_name',
													editDisabled: false,
													formProps,
													formType: 'userProfile',
													validations: [
														{
															type: 'required',
														},
														{
															type: 'minLength',
															value: 3
														}
													]
												}}
											/>
										</div>
										<div className='c-input'>
											<TextInput 
												params={{
													id: 'social_name',
													editDisabled: false,
													formProps,
													formType: 'userProfile',
													validations: [
														{
															type: 'minLength',
															value: 3,
														}
													]													
												}}
											/>
										</div>
										<div className='c-input'>
											<TextInput 
												params={{
													id: 'birth_date',
													editDisabled: false,
													formProps,
													formType: 'userProfile',
													mask: {
														format: '##/##/####',
														placeholder: ['D','D','M','M','A','A','A','A'],
														unformatValue: false,
													},
													validations: [
														{
															type: 'date',
														}
													]
												}}
											/>
										</div>
										<div className='c-form-btn'>
											<Button 
												className='btn-secondary btn-secondary--large btn-secondary--margin-right' 
												onClick={cancelUpdate}
											>
												{t('common.form.btn.cancelBtn')}
											</Button>
											<Button className='btn-primary btn-primary--large' type='submit' disabled={formProps.isSubmitting}>
											{
												formProps.isSubmitting ?
												<BtnLoadingText/>	:
												t('common.form.btn.saveBtn') 
											}
											</Button>
										</div>
									</form>
								}
							</Formik>
						) :
						(
						<>
							<div className='c-profile-avatar'>
							{
								loading ?
								<Skeleton variant='circle' animation='wave' classes={{root: 'skeleton-profile-img'}}/> :
								<Avatar classes={{root: 'profile-avatar__wrapper'}} src={profileImg}>									
									<IconSelector svg='Users' classname={'icon--user-profile-avatar'}/>
								</Avatar>
							}
								<div className='c-profile-img-controls'>
									<label className='btn-secondary btn-secondary--medium btn-secondary--margin-top-2' htmlFor='avatar'>{t('userProfile.updatePhotoBtn')}</label>
									<input type='file' accept='image/png, image/jpeg, image/jpg' id='avatar' disabled={loading} className='visually-hidden' onChange={handleImgUpload} onClick={event => event.target.value = null}/>			
									<Button className='btn-secondary btn-secondary--medium btn-secondary--margin-top-2 btn-secondary--margin-left' disabled={loading} onClick={handleRemovePhoto} >{t('userProfile.removePhotoBtn')}</Button>									
								</div>
							</div>
							<div className='c-profile-info'>
								{
									loading ?
									<Skeleton variant='rectangular' animation='wave' classes={{root: 'skeleton-profile-name'}}/> :
									<span className='profile-name'>{user.social_name !== null ? user.social_name : `${user.first_name} ${user.last_name}`}</span>
								}
								<div className='profile__item'>
									<Avatar classes={{root: 'profile__icon-wrapper'}}>
										<IconSelector svg='Gift' classname={'icon--user-profile-item'}/>
									</Avatar>
									<span className='profile-item-category'>{t('userProfile.birth_date')}</span>
									{
										loading ?
										<Skeleton variant='rectangular' animation='wave' classes={{root: 'skeleton-profile-item'}}/> :
										<span className='profile-item-text'>{formatDateWithoutHour(dayjs(user?.birth_date)) || '-'}</span>
									}
								</div>
								<div className='profile__item'>
									<Avatar classes={{root: 'profile__icon-wrapper'}}>
										<IconSelector svg='Card' classname={'icon--user-profile-item'}/>
									</Avatar>
									<span className='profile-item-category'>{t('userProfile.cpf')}</span>
									{
										loading ?
										<Skeleton variant='rectangular' animation='wave' classes={{root: 'skeleton-profile-item'}}/> :
										<span className='profile-item-text'>{user?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || '-'}</span>
									}
								</div>
							</div>
						</>
						)
					}
				</AccordionDetails>
			</Accordion>
			<Accordion
				defaultExpanded
				classes={{root: 'c-profile-section'}}
			>
				<AccordionSummary
					expandIcon={<IconSelector svg='ChevronDown' classname={'icon--accordion-chevron'}/>}
					classes={{root: 'profile-summary', content: 'profile-summary-content'}}
				>
					<span className='profile-section-title'>{t('userProfile.accountData')}</span>
				</AccordionSummary>
				<AccordionDetails
					classes={{
						root: 'c-profile-details c-profile-details--secondary'
					}}
				>
					<div className='profile__item'>
						<Avatar classes={{root: 'profile__icon-wrapper'}}>
							<IconSelector svg='Users' classname={'icon--user-profile-item'}/>
						</Avatar>
						<span className='profile-item-category'>{t('userProfile.profile_name')}</span>
						{
							loading ?
							<Skeleton variant='rectangular' animation='wave' classes={{root: 'skeleton-profile-item'}}/> :
							<span className='profile-item-text'>{
								user?.profile_name ? translateOptions(user?.profile_name) : '-'}</span>
						}
					</div>
					<div className='profile__item'>
						<Avatar classes={{root: 'profile__icon-wrapper'}}>
							<IconSelector svg='LocationPin' classname={'icon--user-profile-item'}/>
						</Avatar>
						<span className='profile-item-category'>{t('userProfile.unit')}</span><span className='profile-item-text'>-</span>
					</div>
					<div className='profile__item'>
						<Avatar classes={{root: 'profile__icon-wrapper'}}>
							<IconSelector svg='Mail' classname={'icon--user-profile-item'}/>
						</Avatar>
						<span className='profile-item-category'>{t('userProfile.email')}</span>
						{
							loading ?
							<Skeleton variant='rectangular' animation='wave' classes={{root: 'skeleton-profile-item'}}/> :
							<span className='profile-item-text'>{user?.email || ''}</span>
						}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

export default UserProfile