import { useState, useEffect } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {  useParams, useNavigate } from "react-router-dom"
import { Formik } from 'formik'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

import { requestVerifyPin, sendPassword, requestRecovery } from '../utils/authAPI'
import logo from '../../assets/img/logo-cittua.png'
import IconSelector from './IconSelector'
import Modal from './Modal'
import BtnLoadingText from './BtnLoadingText'

function SetPasswordPage({type}) {
	const { t } = useTranslation()
	const { token } = useParams()
	const navigate = useNavigate()
	const [pinVerification,setPinVerification] = useState(false)
	const [sentPassword, setSentPassword] = useState(false)
	const recoveryEmail = useSelector(state => state.user.email)
	const [pinValue, setPinValue] = useState('')
	const [passwordVisibility, setPasswordVisibility] = useState(false)
	const [confirmationVisibility, setConfirmationVisibility] = useState(false)
	const [pinError,setPinError] = useState('')
	const [showTimer, setShowTimer] = useState(false)
	const [isSubmittingPin, setIsSubmittingPin] = useState(false)
	const [isResendingCode, setIsResendingCode] = useState(false)
	const [timeDeadline, setTimeDeadline] = useState(null)
	const [timer, setTimer] = useState({minutes: '2', seconds: '00'})

	const getTimeRemaining = (time) => {
		if (time !== null) {
			const difference = Date.parse(time) - Date.parse(new Date());
			let timeLeft = {}

			if (difference > 0) {
				timeLeft = {
					minutes: Math.floor((difference / 1000 / 60) % 60),
					seconds: Math.floor((difference / 1000) % 60),
				}

				if (timeLeft.seconds < 10) {
					timeLeft['seconds'] = '0' + timeLeft.seconds
				}
			}

			return timeLeft
		}
	}

	const errorInitialValues = {
		lengthError:'',
		numberError:'',
		lowerCaseError:'',
		upperCaseError:'',
		specialCharError:'',
		forbiddenCharError:''
	}
	const [errorMessage, setErrorMessage] = useState(errorInitialValues)

	const recoveryInitialValues = {
		newPassword: '',
		confirmPassword: ''
	}

	const onChangePin = (value) => {
		setPinError('')
		setPinValue(value)
	}

	const onSendCode = () => {
		setIsSubmittingPin(true)
		const requestParams = {
			pin: pinValue,
			userID: token ? token : recoveryEmail,
			type: type,
		}
		requestVerifyPin(requestParams).then((response) => {
			if (response && response.success) {
				setPinVerification(true)
			} else {
				setPinError(response.message)
			}
			setIsSubmittingPin(false)
		})
	}

	const onResendCode = () => {
		setIsResendingCode(true)
		requestRecovery(recoveryEmail).then((response) => {
			if (response.success) {
				let deadline = new Date();
				deadline.setMinutes(deadline.getMinutes(deadline) + 2);
				setTimeDeadline(deadline)
				setShowTimer(true)
				setTimeout(() => {
					setShowTimer(false)
					setIsResendingCode(false)
				}, 120000)
			} else {
				setPinError(response.message)
				setIsResendingCode(false)
			}
		})
	}

	const passwordValidation = (values) => {
		setPinError('')
		const errors = {}
		if (!values.newPassword) {
			errors.newPassword = t('common.inputErrors.fieldRequired')
			setErrorMessage({
				lengthError: t('common.inputErrors.lengthError'),
				numberError: t('common.inputErrors.numberError'),
				lowerCaseError: t('common.inputErrors.lowerCaseError'),
				upperCaseError: t('common.inputErrors.upperCaseError'),
				specialCharError: t('common.inputErrors.specialCharError'),
				forbiddenCharError: t('common.inputErrors.forbiddenCharError')
			})
		} else if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&<>?~_+\-=])(?!.*[ \\*.()[\]{};:,¨"'/|]).{8,12}$/.test(values.newPassword)) {
			if (!/(?=.*[0-9]).{1,}/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					numberError: t('common.inputErrors.numberError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					numberError:''
				}))
			}
			if (!/^.{8,12}$/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					lengthError: t('common.inputErrors.lengthError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					lengthError:''
				}))
			}
			if (!/(?=.*[a-z]).{1,}/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					lowerCaseError: t('common.inputErrors.lowerCaseError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					lowerCaseError:''
				}))
			}
			if (!/(?=.*[A-Z]).{1,}/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					upperCaseError: t('common.inputErrors.upperCaseError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					upperCaseError:''
				}))
			}
			if (!/(?=.*[!@#$%^&<>?~_+\-=]).{1,}/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					specialCharError: t('common.inputErrors.specialCharError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					specialCharError:''
				}))
			}
			if (!/^(?!.*[ \\*.()[\]{};:,¨"'/|]).{1,}$/.test(values.newPassword)) {
				setErrorMessage(prevState => ({
					...prevState,
					forbiddenCharError: t('common.inputErrors.forbiddenCharError')
				}))
			} else {
				setErrorMessage(prevState => ({
					...prevState,
					forbiddenCharError:''
				}))
			}
			errors.newPassword = t('common.inputErrors.invalidPassword')
		} else {
			setErrorMessage({
				lengthError: '',
				numberError: '',
				lowerCaseError: '',
				upperCaseError: '',
				specialCharError: '',
				forbiddenCharError: '',
			})
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = t('common.inputErrors.fieldRequired')
		} else if (values.confirmPassword !== values.newPassword) {
			errors.confirmPassword = t('common.inputErrors.confirmPassInvalid')
		}

		return errors
	}

	const onSubmitPassword = (values,actions) => {
		setPinError('')
		const requestParams = {
			password: values.newPassword,
			userID: token ? token : recoveryEmail,
			pin: pinValue,
			type: type,
		}
		sendPassword(requestParams).then((response) => {
			if (response.success) {
				setSentPassword(true)
			} else {
				setPinError(response.message)
			}
			actions.setSubmitting(false)
		})
	}

	const onBackToLogin = () => {
		navigate('/login')
	}

	const onChangeVisibility1 = () => {
		setPasswordVisibility(!passwordVisibility)
	}

	const onChangeVisibility2 = () => {
		setConfirmationVisibility(!confirmationVisibility)
	}

	useEffect(() => {
		document.title = t(`${type}.pageTitle`)
		if (!recoveryEmail && type === 'recovery') {
			navigate('/login')
		}
	},[])

	useEffect(() => {
		setTimeout(() => {
			setTimer(getTimeRemaining(timeDeadline))
		},1000)
	},[timeDeadline, timer])

	return (
		<div className='c-recovery-page theme--light'>
			<div className='c-recovery-content'>
				{/*TODO: colocar os textos alt das imgs no translation*/}
				<img className='logo-img recovery-img' alt='Logo do Cittua' src={logo}/>
				{
					!pinVerification ? 
					(
						<>
							<h2 className='recovery-title'>{t(`${type}.insertCodeTitle`)}</h2>
							<p className='recovery-text'>{t(`${type}.insertCodeText1`, {email: 'email'})}</p>
							<p className='recovery-text'>{t(`${type}.insertCodeText2`)}</p>
							<ReactInputVerificationCode
								length={6}
								placeholder={''}
								value={pinValue}
								onChange={onChangePin}
							/>
							<div className={pinError ? 'c-error-text' : 'c-error-text c-error-text--hidden'}>
								<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
								<span className='error-text'>{pinError}</span>
							</div>
							<Button className='btn-primary btn-primary--large-full-width btn-primary--margin-top-small' disabled={isSubmittingPin} onClick={onSendCode}>{t(`${type}.btnSendCode`)}</Button>
							<Button className='btn-tertiary btn-tertiary--large-full-width btn-tertiary--margin-top' disabled={isResendingCode} onClick={onResendCode}>
								{t(`${type}.btnResendCode`)}{(showTimer && timer !== undefined) && <span className='timer'> {timer.minutes}:{timer.seconds}</span>}
							</Button>
						</>
					) :
					(
						<>
							<h2 className='recovery-title'>{t(`${type}.formTitle`)}</h2>
							{/*TODO: componentizar esse formulário*/}
							<Formik
								initialValues={recoveryInitialValues}
								validate={values => passwordValidation(values)}
								onSubmit={(values,actions) => onSubmitPassword(values,actions)}
							>
							{
								({
									values,
									errors,
									touched,
									isSubmitting,
									handleSubmit,
									handleBlur,
									handleChange,
									setFieldValue,
								}) => (
									<form className='recovery-form' onSubmit={handleSubmit}>
										<div className='c-input'>
											<label className='label' htmlFor='newPassword'>
												{t(`${type}.fields.newPassword`)}
												<Tooltip 
													title={
														<>
															{/*TODO: Fazer bloco de translation*/}
															<span className='tooltip__text'>Sua senha deve ter:</span>
															<ul>
																<li className='tooltip__list-item'>8 a 12 caracteres</li>
																<li className='tooltip__list-item'>1 letra maiúscula</li>
																<li className='tooltip__list-item'>1 letra minúscula</li>
																<li className='tooltip__list-item'>1 número</li>
																<li className='tooltip__list-item'>1 caracter especial</li>
															</ul>
														</>
													}
													classes={{tooltip: 'c-tooltip'}}
													placement='right-end'
												>
													<div className='tooltip'>
														<IconSelector svg={'QuestionLine'} classname={'icon--tooltip'}/>
													</div>
												</Tooltip>
											</label>
											<input
												id='newPassword'
												name='newPassword'									
												type={passwordVisibility ? 'text' : 'password'}												
												className='input input--password'
												value={values.newPassword}
												onChange={handleChange}
												onBlur={handleBlur}
												disabled={isSubmitting}
											/>
											<button className='btn-icon' type='button' onClick={onChangeVisibility1}>
												<IconSelector svg={passwordVisibility ? 'EyeLine' : 'EyeCloseLine'} classname={'icon--password'}/>
											</button>
											<div className={(errors.newPassword && touched.newPassword) ? 'c-error-text' : 'c-error-text c-error-text--hidden'}>
												<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
												<span className='error-text'>{errors.newPassword}</span>
											</div>
										</div>
										<div className='c-input'>
											<label className='label' htmlFor='confirmPassword'>{t(`${type}.fields.confirmPassword`)}</label>
											<input
												id='confirmPassword'
												name='confirmPassword'
												type={confirmationVisibility ? 'text' : 'password'}
												className='input input--password'
												value={values.confirmPassword}
												onChange={handleChange}
												onBlur={handleBlur}
												disabled={isSubmitting}
											/>
											<button className='btn-icon' type='button' onClick={onChangeVisibility2}>
												<IconSelector svg={confirmationVisibility ? 'EyeLine' : 'EyeCloseLine'} classname={'icon--password'}/>
											</button>
											<div className={(errors.confirmPassword && touched.confirmPassword) ? 'c-error-text' : 'c-error-text c-error-text--hidden'}>
												<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
												<span className='error-text'>{errors.confirmPassword}</span>
											</div>
										</div>
										{
											((errors.newPassword && touched.newPassword && !Object.values(errorMessage).every(value => value === '')) || pinError) && 
											<div className='c-error-message c-error-message--block'>
												{
													pinError ? (
													<>
														<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
														<span className='error-message error-message--block'>{pinError}</span>
													</>
													) : (
													<>
														{
															Object.keys(errorMessage).map(error => (
																errorMessage[error] &&
																<div key={error} className='c-error-message--line'>
																	<IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
																	<span className='error-message'>{errorMessage[error]}</span>
																</div>
															))
														}
													</>
													)
												}
											</div>
										}
										<Button										 	
											classes={{
												root: 'btn-primary btn-primary--large-full-width btn-primary--margin-top'
											}}
											type='submit'
											disabled={isSubmitting}
										>
											{
												isSubmitting ?
												<BtnLoadingText/>
												:
												t(`${type}.btnSendNewPassword`)
											}
										</Button>
									</form>
								)
							}
							</Formik>
						</>
					)
				}
			</div>
			<Modal 
				type='recoveryResult'
				params={{
					openModal: sentPassword,
					closeModal: onBackToLogin,
				}}
			/>
		</div>
	)
}

export default SetPasswordPage