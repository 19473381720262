import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import IconSelector from "../IconSelector";
import { useTranslation } from "react-i18next";
import { deleteForecastItem } from "../../utils/dataAPI";
import { useDispatch } from "react-redux";
import { triggerFetchData } from "../../redux/actions/triggerFetchData";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalDialog = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    setOpenModalTwo(false);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenModalTwo(false);
    if (openModalTwo) {
      dispatch(triggerFetchData(true));
    }
  };

  const handleModal = () => {
    try {
      setOpen(false);
      deleteForecastItem(id);
      setOpenModalTwo(true);
    } catch (err) {
      return err;
    }
  };

  const handleEditBtn = () => {
    navigate(`edit/${id}`);
  };

  return (
    <>
      <div className="c-action-btn">
        <Button className="btn-table-edit" onClick={handleEditBtn}>
          <IconSelector svg="Edit" classname="icon--edit-btn" />
          <p className="edit-btn-text">{t("common.table.action-btn.edit")}</p>
        </Button>
        <Button
          className="btn-table"
          variant="outlined"
          onClick={handleClickOpen}
        >
          <IconSelector svg="ExcluirIcon" className="icon" />
          <p className="delete-btn-text">
            {t("forecasts.button.deleteButton")}
          </p>
        </Button>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          className="Exclusion-Modal"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <IconSelector svg="Notification" className="warning" />
          <h1 className="exclusion-alert">{t("exclusion.alert")}</h1>
          <p>{t("exclusion.warning")}</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconSelector svg="Fechar" className="close" />
        </IconButton>
        <div className="group-buttons">
          <Button className="btn-ModalOne" autoFocus onClick={handleModal}>
            {t("exclusion.persist")}
          </Button>
          <Button className="btn-ModalTwo" autoFocus onClick={handleClose}>
            {t("exclusion.to go back")}
          </Button>
        </div>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModalTwo}
      >
        <DialogTitle
          className="Modal-Two"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          <IconSelector svg="CheckV" className="icon-two" />
          <h1  className="exclusion-alert">{t("forecasts.delete.confirmation")}</h1>
          <p className="exclusion-alert-warning"> {t("forecasts.delete.success")}</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconSelector svg="Fechar" className="close" />
        </IconButton>
        <Button className="btn-Modal" autoFocus onClick={handleClose}>
          <p className="deletion-confirmation">
            {t("forecasts.delete.exclusion")}
          </p>
        </Button>
      </BootstrapDialog>
    </>
  );
};
