import i18n from '../i18n/i18n';

export const headerConfig = {
	components: [
		{
			type: 'pageTitle',
			pages: [
				{
					title: i18n.t('userProfile.pageTitle'),
					path: '/user-profile',
					hasBackBtn: false,
					backBtnPath: null,
				},
				{
					title: i18n.t('users.pageTitle.table'),
					path: '/users',
					hasBackBtn: false,
					backBtnPath: null,
				},
				{
					title: i18n.t('users.pageTitle.create'),
					path: '/users/create',
					hasBackBtn: true,
					backBtnPath: '/users',
				},
				{
					title: i18n.t('users.pageTitle.edit'),
					path: '/users/edit/:itemId',
					hasBackBtn: true,
					backBtnPath: '/users',
				},
				{
					title: i18n.t('nwpForecast.pageTitle'),
					path: '/nwpForecast',
					hasBackBtn: false,
					backBtnPath: null,
				},
				{
					title: i18n.t('forecasts.pageTitle.table'),
					path: '/forecasts',
					hasBackBtn: false,
					backBtnPath: null,
				},
				{
					title: i18n.t('forecasts.pageTitle.create'),
					path: '/forecasts/create',
					hasBackBtn: true,
					backBtnPath: '/forecasts',
				},
				{
					title: i18n.t('forecasts.pageTitle.edit'),
					path: '/forecasts/edit/:itemId',
					hasBackBtn: true,
					backBtnPath: '/forecasts',
				},
				{
					title: i18n.t('alerts.pageTitle.table'),
					path: '/alerts',
					hasBackBtn: false,
					backBtnPath: null,
				},
				{
					title: i18n.t('alerts.pageTitle.create'),
					path: '/alerts/create',
					hasBackBtn: true,
					backBtnPath: '/alerts',
				},
				{
					title: i18n.t('alerts.pageTitle.edit'),
					path: '/alerts/edit/:itemId',
					hasBackBtn: true,
					backBtnPath: '/alerts',
				},
				{
					title: i18n.t('forecasts.pageTitle.details'),
					path: '/forecasts/details/:id',
					hasBackBtn: true,
					backBtnPath: '/forecasts'
				},
				{
					title: i18n.t('rainReport.pageTitle.table'),
					path: '/rain-occurrences',
					hasBackBtn: false,
					backBtnPath: null
				}
			]
		},
		{
			type: 'userProfileMenu',
		}
	]
}