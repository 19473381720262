import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import IconSelector from '../IconSelector';
import { createRoot } from 'react-dom/client';

export const ButtonSelectionMap = ({ icon, isOpenModal, className, buttonClassName }) => {
  const map = useMap();

  useEffect(() => {
    const CustomControl = L.Control.extend({

      onAdd: function () {
        
        const container = L.DomUtil.create('div', `leaflet-bar leaflet-control ${buttonClassName}`);
        const button = L.DomUtil.create('a', `leaflet-bar-part`, container);

        const iconSelectorComponent = <IconSelector classname={className} svg={icon} />;
        const root = createRoot(button); 

        root.render(iconSelectorComponent);

        L.DomEvent.on(button, 'click', function () {
          isOpenModal();
        });

        return container;
      },
    });

    const customControl = new CustomControl({ position: 'bottomright' });
    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map, icon, isOpenModal, className]);

  return null;
};
