import { useTranslation } from "react-i18next";

function RainfallScaleElement({ params,className }) {

  return (
    <div className={`c-scale-item ${params.itemClass}`}>
      <div className='scale-item' style={{ backgroundColor: params.color }}>
      </div>
      {params.label && <span className={className}>{params.level}</span>}
    </div>
  );
}


export function RainfallScale({
  scaleValues: propScaleValues,
  scaleTitle,
  containerClass: propContainerClass,
  titleClass: propTitleClass,
  propLevelClass
}) {
  const { t } = useTranslation();

  const defaultScaleValues = [
    {
      level: 1,
      color: "#000080",
      label: true,
    },
    {
      level: 3,
      color: "#1A33C0",
      label: false,
    },
    {
      level: 4,
      color: "#3366FF",
      label: false,
    },
    {
      level: 5,
      color: "#1A99FF",
      label: true,
    },
    {
      level: 6,
      color: "#00CCFF",
      label: false,
    },
    {
      level: 7,
      color: "#009C80",
      label: false,
    },
    {
      level: 8,
      color: "#006C00",
      label: true,
    },
    {
      level: 9,
      color: "#2E9400",
      label: false,
    },
    {
      level: 10,
      color: "#5DBB00",
      label: false,
    },
    {
      level: 12,
      color: "#84DD4C",
      label: true,
    },
    {
      level: 15,
      color: "#D4FF8B",
      label: false,
    },
    {
      level: 20,
      color: "#FFCD05",
      label: false,
    },
    {
      level: 25,
      color: "#DE0000",
      label: true,
    },
    {
      level: 30,
      color: "#FF99CC",
      label: false,
    },
    {
      level: 40,
      color: "#D295BB",
      label: false,
    },
    {
      level: 50,
      color: "#8C7B90",
      label: true,
    },
    {
      level: 100,
      color: "#665A69",
      label: false,
    },
    {
      level: 200,
      color: "#000000",
      label: true,
    },
  ];

  const scaleValues = propScaleValues || defaultScaleValues;
  const containerClass = propContainerClass || "default-container-class";
  const titleClass = propTitleClass || "default-title-class";
  const levelClass = propLevelClass || "default-level-class";

  return (
    <div className={`c-scale ${containerClass}`}>
      <p className={`scale-title ${titleClass}`}>{t(scaleTitle)}</p>
      <div className="c-rainfall-scale">
        {scaleValues.map((value, index) => (
          <RainfallScaleElement className={levelClass} key={index} params={value} />
        ))}
      </div>
    </div>
  );
}

export default RainfallScale;
