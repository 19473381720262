import dayjs from "dayjs";
import { browserLanguage } from "../i18n/i18n";

export function formatDate(date) {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }

  const dia = date.getDate().toString().padStart(2, "0");
  const mes = (date.getMonth() + 1).toString().padStart(2, "0");
  const ano = date.getFullYear();
  const hora = date.getHours().toString().padStart(2, "0");
  const minutos = date.getMinutes().toString().padStart(2, "0");

  const formattedDate = `${dia}/${mes}/${ano} às ${hora}:${minutos}h`;

  return formattedDate;
}

export function formatDateHour(date) {
  date = dayjs(date);

  const formatString =
    browserLanguage() === "pt-br" || "pt-pt"
      ? "DD/MM/YYYY [-] HH:mm"
      : "MM/DD/YYYY [-] HH:mm";

  return date.format(formatString);
}

export function formatDateWithoutHour(date) {
  date = dayjs(date);

  const formatString =
    browserLanguage() === "pt-br" ? "DD/MM/YYYY" : "MM/DD/YYYY";

  return date.format(formatString);
}
