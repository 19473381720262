import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";
import PermissionsControl from "../PermissionsControl";
import { forecastTableConfig } from "../../data/ForecastTableConfig/forecastTableConfig";
import { HeaderForecastTable } from "./header-forecast-table/HeaderForecastTable";

function ForecastTable() {
  
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("forecasts.pageTitle.table");
  }, [t]);

  const buildRequestParams = (data, search, tableParams) => {
    let requestParams = {
      options: {
        page: tableParams.page,
        perPage: tableParams.perPage,
        sortBy: tableParams.sortBy,
        order: tableParams.order,
        search: search.length > 0 ? search : [],
      },
      requestUrl: forecastTableConfig.requestUrls.get,
      tableType: "forecasts",
      ...(data.signal && { signal: data.signal }),
    };
    return requestParams;
  };

  return (
    <div className="c-main-content c-main-content--table">
      <PermissionsControl
        role={forecastTableConfig.permission}
        type="componentPage"
        action="read"
      >
        <CustomTable
          params={{
            tableType: "forecasts",
            configTable: forecastTableConfig,
            HeaderRoot: HeaderForecastTable,
            buildRequestParams: buildRequestParams,
          }}
        />
      </PermissionsControl>
    </div>
  );
}
export default ForecastTable;
