import { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';

import IconSelector from '../IconSelector';
import { sidebarConfig } from '../../data/sidebar-config';

function SidebarExpandItem({props}) {
	const [openItem, setOpenItem] = useState(false)

	const handleExpandItem = () => {
		setOpenItem(!openItem)
	}

	let indexRef = {}

	props.itemConfig.subItemsList.forEach(subItem => {
		indexRef[subItem.id] = sidebarConfig.indexOrder.findIndex(element => element.parent === props.itemConfig.id && subItem.id === element.id)
	})

	useEffect(() => {
		if (!props.sidebarStatus && openItem) {
			props.sidebarControl();
		}
	},[openItem])

	useEffect(() => {
		if (!props.sidebarStatus && openItem) {
			handleExpandItem();
		}
	},[props.sidebarStatus])

	return (
		<>
		<ListItem
			classes={{root: props.sidebarStatus ? 'sidebar-item' : 'sidebar-item sidebar-item--closed'}}
			onClick={handleExpandItem}
			selected={Object.values(indexRef).some(element => element === props.selectedIndex)}
			button
		>
			<ListItemAvatar classes={{root: 'sidebar-item__icon-wrapper'}}>
				<Avatar classes={{root: 'sidebar-item__icon'}}>
					<IconSelector svg={props.itemConfig.itemIcon} classname={'icon--sidebar'}/>
				</Avatar>
			</ListItemAvatar>
			<ListItemText classes={{primary: props.sidebarStatus ? 'sidebar-item__text': 'sidebar-item__text--hidden'}} primary={props.itemConfig.itemText}/>
			{openItem ? <IconSelector svg='ChevronDown' classname='icon--sidebar'/> : <IconSelector svg='ChevronRight' classname='icon--sidebar'/>}
		</ListItem>
		<Collapse in={openItem} unmountOnExit>
			{
				props.itemConfig.subItemsList.map((subItem) => (
					<ListItem
						classes={{root: 'sidebar-sub-item'}}
						key={subItem.itemText}
						onClick={() => props.onClickFunction(indexRef[subItem.id])}
						selected={props.selectedIndex === indexRef[subItem.id]}
						button
					>
						<ListItemText classes={{primary: 'sidebar-item__text'}} primary={subItem.itemText}/>
					</ListItem>
				))
			}
		</Collapse>
		</>
	)
}

export default SidebarExpandItem