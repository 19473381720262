import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Skeleton from '@mui/material/Skeleton'
import Avatar from '@mui/material/Avatar'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'

import MapComponent from './Map'
import IconSelector from './IconSelector'
import RainfallScale from './RainfallScale'
import  { getMapInitialOptions } from '../utils/dataAPI'

function NwpForecast() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(true)
	const [initialMapSettings, setInitialMapSettings] = useState(null)
	const [mapError, setMapError] = useState(false)
	const [dateShortcut, setDateShortcut] = useState(null)
	const [date,setDate] = useState(dayjs())
	const [time,setTime] = useState(dayjs())

	const weekDay = (day) => {
		const firstTwoChars = day.substring(0, 2); 
		
		const newDay = {
			'Do': 'DOM',
			'2ª': 'SEG',
			'3ª': 'TER',
			'4ª': 'QUA',
			'5ª': 'QUI',
			'6ª': 'SEX',
			'Sá': 'SAB',
		}
		
		return (
			newDay.hasOwnProperty(firstTwoChars) ? 
			newDay[firstTwoChars] : 
			firstTwoChars.toUpperCase()
		);
	}

	const handleDateShortcut = (event, newDate) => {
		let newDay = null
		switch (newDate) {
			case 'today': 
				newDay = dayjs()
				break
			case 'yesterday':
				newDay = dayjs().subtract(1, 'day')
				break
			case 'last_week':
				newDay = dayjs().subtract(1, 'week')
				break
			case 'last_month':
				newDay = dayjs().subtract(1, 'month')
				break
			default:
				newDay = null
		}
		setDateShortcut(newDate)
		if (newDay) {
			setDate(newDay)
			setTime(newDay)
		}
	}

	const handleDateCalendar = (newValue) => {
		setDateShortcut(null)
		setDate(newValue)
	}

	const handleTimer = (newValue) => {
		setDateShortcut(null)
		setTime(newValue)
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const initialData = await getMapInitialOptions(dispatch)
				if (initialData && initialData.success) {
					setInitialMapSettings(initialData.data)
				} else {
					setMapError(true)
				}
				setLoading(false)
			} catch (error) {
				setMapError(true)
				setLoading(false)
			}
		}

		fetchData()
	},[])

	useEffect(() => {
		if (mapError) {
			setMapError(false)
			const fetchData = async () => {
				setLoading(true)
				try {
					const initialData = await getMapInitialOptions(dispatch)
					if (initialData && initialData.success) {
						setInitialMapSettings(initialData.data)
					} else {
						setMapError(true)
					}
					setLoading(false)
				} catch (error) {
					setMapError(true)
					setLoading(false)
				}
			}

			fetchData()
		}
	}, [date,time])

	useEffect(() => {
		document.title = t('nwpForecast.pageTitle')
	})

	return (
		<div className='c-forecast-content'>
			<div className='c-map'>
				{loading ?
					<Skeleton 
						variant='rectangular' 
						animation='wave' 
						classes={{root: 'skeleton-map'}}
					/> 
					:
					mapError ? 
					<div className='c-map-error'>
						<div className='error-page-content'>
							<Avatar classes={{root: 'error-page__icon-wrapper'}}>
								<IconSelector svg='Warning' classname='icon--warning-big'/>
							</Avatar>
							<div className='error-page__text-block'>
								<h1 className='error-page-title'>
									{t('nwpForecast.mapError.title')}
								</h1>
								<p className='error-page-text'>
									{t('nwpForecast.mapError.text')}
								</p>
							</div>
						</div>					
					</div> 
					:
					<MapComponent 
						params={{
							initialMapSettings: initialMapSettings,
							lastUpdateFlag:true,
							drawingControls: false,
							onError: setMapError,
							date: date,
							time: time,
							previewForecasts: false,
						}}
					/>
				}
			</div>
			<div className='c-map-controls'>
				<p className='map-controls-title'>{t('nwpForecast.controls-title')}</p>
				<ToggleButtonGroup 
					className='map-toggle-group'
					value={dateShortcut}
					onChange={handleDateShortcut}
					exclusive
				>
					<ToggleButton 
						className='map-toggle-btn' 
						value='today'
						disabled={loading}
					>
						{t('nwpForecast.date_shortcut.today')}
					</ToggleButton>
					<ToggleButton 
						className='map-toggle-btn' 
						value='yesterday'
						disabled={loading}
					>
						{t('nwpForecast.date_shortcut.yesterday')}
					</ToggleButton>
					<ToggleButton 
						className='map-toggle-btn' 
						value='last_week'
						disabled={loading}
					>
						{t('nwpForecast.date_shortcut.last_week')}
					</ToggleButton>
					<ToggleButton 
						className='map-toggle-btn' 
						value='last_month'
						disabled={loading}
					>
						{t('nwpForecast.date_shortcut.last_month')}
					</ToggleButton>
				</ToggleButtonGroup>
				<DateCalendar
					className='map-date-calendar-control'
					dayOfWeekFormatter={weekDay}
					value={date}
					onChange={handleDateCalendar}
					maxDate={initialMapSettings && dayjs(initialMapSettings.max_dt_available)}
					minDate={initialMapSettings && dayjs(initialMapSettings.min_dt_available)}
					disabled={loading}
				/>
				<div className='c-time-input'>
					<p className='time-input-label'>{t('nwpForecast.time_input.label')}</p>
					<TimePicker
						ampm={false}
						views={['hours']}
						format='HH:00'
						className='time-input'
						value={time}
						onChange={handleTimer}
						disabled={loading}
					/>
				</div>
				<RainfallScale/>
			</div>
		</div>
	)
}

export default NwpForecast