function TermsTextPt() {
  return (
    <>
      <p>Prezado(a)</p>
      <p>
        Estes Termos de Uso disciplinarão a relação entre a <b>Cittua</b> e{" "}
        <b>Vocês</b>.
      </p>
      <p>
        Para utilizar os serviços da Plataforma, <b>Você</b> deverá concordar e
        aceitar os Termos de Uso e a Política de Privacidade.
      </p>
      <br></br>
      <h4>- CONDIÇÕES GERAIS -</h4>
      <ol>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Âmbito</u>.
              </b>{" "}
              Os presentes Termos e Condições Gerais de Uso (denominado somente
              “Termos de Uso” ou “Termos”) vinculam todas as atividades
              desenvolvidas e serviços oferecidos por <b>Nós</b> (doravante
              assim denominada ou somente “Plataforma”), que engloba o website e
              seus subdomínios, aplicativos, programas, API de dados e demais
              extensões. A Plataforma é de propriedade da{" "}
              <b>Wiiglo Tecnologia da Informação LTDA.</b>, sociedade inscrita
              no CNPJ sob o nº 18.101.387/0001-39, com sede na Rua Fonseca
              Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200, e
              está sob sua responsabilidade e gestão.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Atuação</u>.
              </b>{" "}
              <b>Nós</b> somos uma Plataforma que oferece o serviço de
              classificação de riscos de determinado ambiente a partir da
              análise de dados.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Sujeitos</u>.
              </b>{" "}
              Utilizaremos a expressão <b>“Vocês”</b> para nos referirmos aos
              usuários da Plataforma, estes entendidos como qualquer pessoa que
              utilize os recursos da <b>Cittua</b>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Adesão e Aceite</u>.
              </b>{" "}
              Pelo presente instrumento, <b>Vocês</b> concordam que:
              <ol>
                <br></br>
                <li className="sub-item sub-item--roman-count">
                  Deverão aceitar nossos Termos de Uso e Política de
                  Privacidade, para fins de cadastro, controle de acesso e
                  execução de melhorias na Plataforma;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Clicando na caixa de aceitação dos Termos devem aderir e
                  concordar em se submeter ao disposto nos presentes Termos;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Violação das Condições</u>.
              </b>{" "}
              Na eventualidade de <b>Você</b> descumprir qualquer das condições
              aqui previstas, <b>Nós</b> nos reservamos o direito de encerrar
              suas respectivas contas e suspender ou anular seu acesso à
              Plataforma, sem necessidade de aviso prévio.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Modificações</u>.
              </b>{" "}
              <b>Nós</b> nos reservamos o direito de, a qualquer momento,
              modificar os Termos de Uso, sempre com o envio de avisos para sua
              ciência, sem qualquer responsabilização da <b>Cittua</b> devido a:
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Quaisquer modificações efetuadas por <b>Nós</b> na plataforma,
                  que entrarão em vigor imediatamente após sua publicação{" "}
                  <em>online</em>.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- MODO DE UTILIZAÇÃO -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Dinâmica</u>.
              </b>{" "}
              <b>Vocês</b> poderão se cadastrar na <b>Nossa</b> Plataforma
              fornecendo os dados conforme previsto na Política de Privacidade
              ou através de sua conta no Facebook ou Google. Uma vez
              cadastrados, poderão acessar a Plataforma e utilizar todas as suas
              funcionalidades.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Incorreção de Informações Cadastrais</u>.
              </b>{" "}
              <b>Você</b>, para utilizar os Serviços da Plataforma, deverá
              prestar as informações exigidas no momento do cadastro, conforme
              disposto na Política de Privacidade.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>Nós</b> nos reservamos o direito de impedir, a <b>Nosso</b>{" "}
                  critério, novos cadastros, ou cancelar os já efetuados, em
                  caso de ser detectada anomalia que considere, em <b>Nossa</b>{" "}
                  análise, revestida de erro grave ou demonstre tentativa
                  deliberada de burlar as regras aqui descritas, obrigatórias
                  para todos <b>Vocês</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Uso de Cadastro por Terceiros</u>.
              </b>{" "}
              Os cadastros na plataforma são individuais e intransferíveis,
              devendo a <b>Cittua</b> ser imediatamente notificada sobre
              qualquer uso não autorizado de sua conta, em tempo hábil, não
              sendo <b>Nós</b> responsáveis por quaisquer prejuízos ou danos
              oriundos do uso de “login” e senha por terceiro, com ou sem o
              consentimento do usuário.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>Vocês</b> deverão: (i) Ser cuidadosos com os dados de sua
                  identificação individual sempre que acessarem a Internet; (ii)
                  Tomar outras medidas necessárias para se protegerem de danos,
                  inclusive fraudes online e off-line; (iii) Ser diligentes no
                  uso do conteúdo disponibilizado na <b>Nossa</b> Plataforma,
                  sabendo de suas responsabilidades; e (iv) Cumprir
                  rigorosamente todas as determinações destes Termos de Uso.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Obrigações de Vocês</u>.
              </b>{" "}
              É de obrigação de <b>Vocês</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Não utilizar a <b>Nossa</b> Plataforma para qualquer propósito
                  que seja ilícito ou proibido pelos termos de uso e/ou pelas
                  normas aplicáveis;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Autorizar que <b>Nós</b> possamos enviar comunicações por
                  e-mail, telefone ou mensagens instantâneas, inclusive de
                  caráter publicitário;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Apresentar comportamento honesto e de boa fé;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Informar corretamente e com precisão todos os seus dados, para
                  fins cadastrais.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Obrigações da Plataforma</u>.
              </b>{" "}
              É <b>Nossa</b> obrigação:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Disponibilizar os serviços da Plataforma de forma segura e
                  estável;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Manter, segundo <b>Nosso</b> julgamento e possibilidades de
                  mercado, a atualização constante da plataforma visando seu
                  aperfeiçoamento e adaptação às novas tecnologias disponíveis;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Proteger a confidencialidade de todas as informações de{" "}
                  <b>Vocês</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Idoneidade</u>.
              </b>{" "}
              <b>Vocês</b> declaram, ao consentir com este documento para
              utilizar a Plataforma, que detém idoneidade íntegra, ficando
              ciente, desde já, que as informações cadastrais falsas ou
              inverídicas registradas ou fornecidas durante o processo
              eletrônico de contratação de serviços podem constituir crime.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Proibições</u>.
              </b>{" "}
              É proibido que <b>Vocês</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Utilizem qualquer vírus, worm, cavalo de Tróia, time bomb,
                  spyware ou outro código, arquivo ou programa de computador que
                  seja prejudicial ou invasivo ou que possa ser ou seja
                  destinado a danificar, sequestrar, desabilitar, interferir
                  com, ou perturbar a operação de, ou monitorar o uso de
                  qualquer hardware, software ou equipamento;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Utilizem qualquer material que dê origem a responsabilidade
                  civil ou criminal, que encoraje conduta que constitui uma
                  ofensa criminal ou que encoraje ou forneçam informações
                  instrutivas sobre atividades ilegais ou atividades como
                  hacking e cracking;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Utilizem a Plataforma para qualquer finalidade ilegal ou
                  fraudulenta;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Interfiram ou perturbem a operação da Plataforma ou violem
                  quaisquer exigências, procedimentos, políticas ou regulamentos
                  da <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Restrinjam ou inibam qualquer outra pessoa de utilizar a
                  Plataforma;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Removam qualquer aviso de direitos autorais, de marca
                  comercial ou outros direitos de propriedade da <b>Cittua</b>{" "}
                  ou materiais provenientes da Plataforma;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Criem um banco de dados transferindo e armazenando Conteúdo da
                  Plataforma;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Utilizem dados/informações disponibilizados na Plataforma em
                  outros ambientes sem a devida referência à <b>Cittua</b>.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- ACESSO E RESTRIÇÕES DE FUNCIONALIDADE -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Restrições</u>.
              </b>{" "}
              Não é permitido acessar <b>Nossas</b> áreas de programação, seu
              banco de dados ou qualquer outro conjunto de informações que faça
              parte da atividade de webmastering, realizar ou permitir que se
              realize qualquer tipo de engenharia reversa, tradução,
              decompilação, cópia, modificação, reprodução, locação, sublocação,
              sublicenciamento, publicação, divulgação, transmissão, empréstimo,
              distribuição ou, de qualquer outra forma, a disposição de
              ferramentas de consulta deste website e de suas funcionalidades
              para terceiros sem a nossa prévia e expressa autorização, ficando
              sujeito quem o fizer a penalidades da legislação brasileira, sem
              prejuízo da obrigação de reparar os danos que causarem. Esta
              restrição inclui qualquer tentativa de incorporar quaisquer
              informações da plataforma em qualquer outro diretório, produto ou
              serviço.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- REMUNERAÇÃO E CANCELAMENTO -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Remuneração</u>.
              </b>{" "}
              Para utilizar as funcionalidades oferecidas pela Plataforma,{" "}
              <b>Vocês</b> não precisarão realizar nenhuma contraprestação
              pecuniária
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cancelamento do Cadastro</u>.
              </b>{" "}
              Caso <b>Você</b> cancele seu cadastro na Plataforma, todos os seus
              registros serão excluídos, ressalvadas as hipóteses em que haja a
              necessidade de retenção dos registros para cumprimento de
              obrigação legal ou regulatória.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- EXCLUSÃO DE GARANTIAS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Disponibilidade</u>.
              </b>{" "}
              A plataforma está disponível para uso de <b>Vocês</b> e é
              oferecida "no estado em que se encontra" e "conforme disponível".{" "}
              <b>Nós</b> declinamos qualquer responsabilidade por garantias
              implícitas de comerciabilidade, adequação a um determinado fim e
              de retorno financeiro. Além disso, <b>Nós</b> não garantimos que
              os serviços serão fornecidos sem interrupções ou erros.{" "}
              <b>Você</b> aceita que todo o risco decorrente da utilização da
              nossa Plataforma é da sua exclusiva responsabilidade.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- LIMITAÇÃO DE RESPONSABILIDADE -</h4>
        <li className="invisible-item list-item">
          <ol>
            <br></br>
            <li className="list-item">
              <b>Nós</b> realizamos apenas a intermediação entre <b>Vocês</b> e
              responderemos exclusiva e limitadamente pelos serviços prestados.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>Nós</b> reiteramos que não constituímos com nenhum de{" "}
                  <b>Vocês</b>: (i) sociedade, associação ou representação; (ii)
                  prestação de qualquer serviço diverso do objeto deste
                  instrumento particular, e/ou (iii) relação empregatícia.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Responsabilidade</u>.
              </b>{" "}
              <b>Nós</b> não seremos responsáveis por quaisquer perdas ou danos
              sofridos, resultantes de: (i) qualquer informação errada ou
              incompleta que seja fornecida por <b>Você</b>; (ii) qualquer
              fraude, declaração fraudulenta ou violação do dever, ou violação
              de qualquer uma das condições por <b>Você</b>; (iii) falhas nas
              conexões; (iv) problemas no processamento de dados por culpa de
              terceiros; (v) produtos ou serviços de terceiros, mesmo que
              adquiridos através da Plataforma.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>Nós</b> fornecemos a <b>Você</b> informações quanto a
                  probabilidade de determinado evento acontecer em um certo
                  local, havendo chances de as probabilidades não se
                  concretizarem. Sendo assim, não nos responsabilizamos por
                  eventuais imprecisões nas informações fornecidas através da
                  Plataforma .
                </li>
                <li className="sub-item list-item">
                  Da mesma forma, <b>Nós</b> não nos responsabilizamos por
                  eventos não avisados pela Plataforma que venham a acontecer.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Violação de Direito de Terceiros</u>.
              </b>{" "}
              <b>Nós</b> não nos responsabilizamos perante <b>Você</b> em
              relação a qualquer violação de direito de terceiros.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Danos</u>
              </b>{" "}
              Em nenhum caso, <b>Nós</b> seremos responsáveis por danos
              indiretos, incidentais, especiais, exemplares, punitivos ou
              consequentes, incluindo, danos pessoais ou à propriedade,
              relacionados com, ou de outra forma resultantes, de qualquer uso
              da plataforma que <b>Você</b> possa sofrer devido às ações
              realizadas ou não realizadas através desta, ainda que decorrentes
              da conduta de terceiros.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Indenização</u>.
              </b>{" "}
              <b>Vocês</b> indenizarão a <b>Cittua</b>, diretores,
              administradores, colaboradores, representantes e empregados por
              qualquer demanda promovida por terceiros decorrentes de suas
              atividades na Plataforma, e pelo descumprimento dos termos de uso
              e condições e demais políticas da mesma, e ainda pela violação de
              qualquer lei ou direitos de terceiros, incluindo honorários
              advocatícios
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- INATIVAÇÃO DO CADASTRO, DÚVIDAS E SUGESTÕES -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Inativação do Cadastro, Dúvidas e Sugestões</u>.
              </b>{" "}
              Em caso de inativação da conta, dúvida ou sugestões sobre nossos
              Termos de Uso ou qualquer outra informação, <b>Você</b> poderá
              entrar em contato conosco através do espaço destinado ao
              atendimento do usuário na Plataforma.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Vedação e Desativação de Cadastro</u>.
              </b>{" "}
              Como forma de manter a confiança e respeito nesta Plataforma,
              serão vedados atos ilegais ou incompatíveis com a proposta de
              zelar pela prestação dos serviços de qualidade. Assim, poderá
              resultar em suspensão ou inativação do seu cadastro se <b>Você</b>{" "}
              incorrer em qualquer das hipóteses elencadas abaixo:
              <ol>
                <br></br>
                <li className="sub-item sub-item--roman-count">
                  Promoção da violência e/ou discriminação, baseada em questões
                  de raça, sexo, religião, nacionalidade, orientação sexual ou
                  de qualquer outro tipo;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Violação de quais leis, principalmente no que tange aos
                  direitos autorais, propriedade intelectual, tais como cópias
                  não autorizadas, utilização de imagens, sons, movimentos ou
                  textos sem autorização do autor, sejam elas marcas, réplicas
                  e/ou falsificações;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Violação do disposto na Política de Privacidade da Plataforma;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Compartilhamento de dados incompletos ou diferentes dos
                  disponibilizados pela <b>Cittua</b>;
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- ENGENHARIA DA PLATAFORMA -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Aperfeiçoamento</u>.
              </b>{" "}
              <b>Vocês</b> concordam que todo e qualquer sistema, plataforma ou
              serviço, independentemente de seu produtor ou características, é
              uma espécie de produto que está sempre em atualização e
              aperfeiçoamento.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Erros de Funcionamento</u>.
              </b>{" "}
              Eventuais erros no funcionamento da Plataforma serão corrigidos o
              mais breve possível, durante o período que for necessário para
              manutenção. <b>Nós</b> não pretendemos que o funcionamento do
              servidor da Plataforma, bem como de seu sistema, do seu banco de
              dados, software e de seu website, sejam livres de erros, falhas ou
              de interrupções.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- PROPRIEDADE INTELECTUAL -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Utilização da Propriedade Intelectual</u>.
              </b>{" "}
              O uso comercial da expressão <b>"Cittua"</b>, como marca, nome
              empresarial ou nome de domínio, e o logotipo são de propriedade
              exclusiva dos Nossos proprietários, conforme disposto no
              respectivo ato constitutivo da organização. Da mesma forma,
              direitos autorais e outros direitos passíveis de proteção por
              propriedade industrial como estrutura, os conteúdos das telas
              relativas à Plataforma, assim como os programas relacionados a
              mesma, software, website, bancos de dados, redes e arquivos. Todos
              os direitos estão protegidos, no Brasil e internacionalmente por
              leis e tratados internacionais de direito autoral, marcas,
              patentes, modelos e desenhos industriais. Aos Nossos proprietários
              reservam-se todos os direitos relativos a autorias, marcas,
              patentes, modelos e desenhos industriais, quer sejam de sua
              propriedade ou licenciados em seu favor, a si.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Não é permitido reproduzir, duplicar, copiar, vender,
                  revender, visitar, ou de qualquer outra forma explorar para
                  fins comerciais o conteúdo da Plataforma sem o prévio
                  consentimento escrito dos Nossos proprietários.
                  Particularmente, é expressamente proibida a utilização de
                  mineração de dados, robôs, ou outras ferramentas de coleta e
                  extração de dados para extrair, de forma isolada ou
                  recorrente, qualquer parte substancial da Plataforma visando
                  sua reutilização.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Relações com Terceiros</u>.
              </b>{" "}
              A Plataforma poderá fornecer links de acesso a outros websites, o
              que não significa que esses sites sejam de propriedade ou operados
              por <b>Nós</b>. Como não possuímos controle sobre esses sites,{" "}
              <b>Nós</b> não seremos responsáveis pelos conteúdos, práticas e
              serviços ofertados nos mesmos. A presença de links para outros
              sites não implica relação de sociedade, de supervisão, de
              cumplicidade ou solidariedade da <b>Cittua</b> para com esses
              sites e seus conteúdos.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- CONDIÇÕES FINAIS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Suspensão e Cancelamento do Acesso</u>.
              </b>{" "}
              Na eventualidade de um descumprimento de qualquer disposição dos
              Termos de Uso, <b>Você</b> reconhece e aceita que <b>Nós</b>{" "}
              podemos, sem aviso prévio, interromper ou suspender, temporária ou
              permanentemente, parte ou a totalidade, do acesso à plataforma.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Advertências e Suspensão</u>.
              </b>{" "}
              Sem prejuízo de outras medidas cabíveis, <b>Nós</b> poderemos
              advertir, suspender, temporária ou definitivamente, seu acesso, a
              qualquer tempo, se:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  não cumprir com qualquer dispositivo dos termos de uso ou da
                  política de privacidade;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  descumprir com quaisquer de suas obrigações ou proibições
                  assumidas ao utilizar os serviços da plataforma;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  utilizar, sem autorização, nome e/ou marca da <b>Cittua</b>,
                  em parte ou na sua totalidade, e violar direitos de
                  propriedade intelectual da <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  não puder ser verificada a identidade ou se qualquer
                  informação fornecida por este estiver incorreta;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  compartilhar dados incompletos ou diferentes dos
                  disponibilizados pela <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  compartilhar dados/informações disponibilizados na Plataforma
                  em outros ambientes sem a devida referência à <b>Cittua</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Tratamento dos Dados Pessoais</u>.
              </b>{" "}
              Para que a Plataforma tenha qualidade e para que <b>Vocês</b>{" "}
              possam obter resultados de forma rápida e segura, é necessário o
              fornecimento de dados pessoais, nos termos dispostos na Política
              de Privacidade da <b>Cittua</b>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Nulidades</u>.
              </b>{" "}
              Se qualquer cláusula deste instrumento for considerada ilegal,
              inválida ou inaplicável, no seu todo ou em parte, ao abrigo de
              qualquer lei, essa cláusula ou parte do mesmo será nesta exata
              medida entendida como não fazendo parte dos Termos de Uso, sendo
              que a legalidade, validade e aplicabilidade das demais cláusulas
              não serão afetadas.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- LEI APLICÁVEL E JURISDIÇÃO -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              Os presentes Termos de Uso são regidos pelas leis brasileiras,
              tendo como definido o foro da Comarca Central do Rio de Janeiro
              como competente para dirimir eventuais controvérsias oriundas
              destes, em prejuízo de qualquer outro, por mais privilegiado que
              seja ou venha a ser, devendo-se dar prioridade, sempre, às
              tentativas de resolução por conciliação ou mediação.
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}

function PrivacyTextPt() {
  return (
    <>
      <p>Seja bem-vindo(a)!</p>
      <br></br>
      <p>Ficamos muito felizes por escolher a Plataforma Cittua!</p>
      <br></br>
      <p>
        Caso esteja com muita pressa em acessar a Plataforma, disponibilizamos
        um breve resumo no quadro abaixo.
      </p>
      <br></br>
      <div className="frame">
        <h4>DIREITOS DOS TITULARES DE DADOS PESSOAIS</h4>
        <br></br>
        <p>
          <b>Vocês</b> são os titulares (pessoas naturais) de dados pessoais a
          quem se referem os dados pessoais que serão coletados por <b>Nós</b>.
        </p>
        <br></br>
        <p>
          A legislação brasileira concede certos direitos à Pessoa Física quanto
          a seus dados pessoais. Dessa forma, Nós garantimos transparência e
          controle de acesso aos titulares de dados pessoais,
          proporcionando-lhes a melhor experiência possível durante a utilização
          da Plataforma. Os direitos do titular dos dados pessoais são os
          seguintes:
        </p>
        <br></br>
        <ol>
          <li className="item item--alpha-count">
            Direito de confirmação da existência de tratamento - Direito de
            solicitar a confirmação da existência de tratamento aos seus dados
            pessoais através de informações claras sobre a origem dos dados, a
            inexistência de registro, os critérios utilizados e a finalidade do
            tratamento;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito de acesso - direito de ser informado e solicitar acesso aos
            dados pessoais tratados por <b>Nós</b>;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito de retificação - direito de solicitar que <b>Nós</b>{" "}
            alteremos ou atualizemos os seus dados pessoais quando os mesmos
            estiverem incorretos ou incompletos;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito de exclusão - direito de solicitar a eliminação de seus
            dados pessoais coletados por <b>Nós</b>;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito de restrição - direito de solicitar que <b>Nós</b> deixemos,
            temporária ou permanentemente, de processar todos ou alguns dos seus
            dados pessoais;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito de oposição – direito de se opor, a qualquer momento, (i) ao
            processamento de seus dados pessoais por motivos relacionados à sua
            situação particular e (ii) à manipulação dos seus dados pessoais
            para fins de marketing direto;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito à portabilidade de dados - direito de solicitar uma cópia
            dos seus dados pessoais em formato eletrônico e/ou transmitir os
            referidos dados pessoais para utilização no serviço de terceiros;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Direito a não se submeter a decisões automatizadas - direito de não
            se submeter a decisões tomadas de forma unicamente automatizada,
            incluindo no que diz respeito à definição de perfis, caso a decisão
            exerça efeito jurídico sobre Você ou produza um efeito igualmente
            significativo.
          </li>
        </ol>
      </div>
      <br></br>
      <table>
        <thead>
          <tr className="table-header">
            <th colSpan="3">
              <h5>INFORMAÇÕES / DADOS PESSOAIS COLETADOS E SUA FINALIDADE</h5>
              <span className="table-text">
                Na tabela abaixo exibimos quais dados serão coletados, sua
                categoria e finalidade.
              </span>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>
              <h6>DADO COLETADO</h6>
            </th>
            <th colSpan="2">
              <h6>CLASSIFICAÇÃO</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="table-text">Nome completo;</span>
              <span className="table-text">Idade;</span>
              <span className="table-text">Email;</span>
              <span className="table-text">Endereço;</span>
              <span className="table-text">Telefone;</span>
              <span className="table-text">CPF;</span>
              <span className="table-text">Gênero;</span>
              <span className="table-text">Etnia;</span>
            </td>
            <td colSpan="2">
              <span className="table-text">Dados cadastrais</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="table-text">Geolocalização;</span>
            </td>
            <td colSpan="2">
              <span className="table-text">Dados de utilização</span>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <span className="table-text">
                Os dados coletados serão utilizados com as seguintes
                finalidades:
              </span>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>
              <h6>DADO COLETADO</h6>
            </th>
            <th>
              <h6>FINALIDADE</h6>
            </th>
            <th>
              <h6>BASE LEGAL</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="table-text">Dados cadastrais</span>
            </td>
            <td>
              <span className="table-text">Te identificar;</span>
              <span className="table-text">Validar seu acesso;</span>
              <span className="table-text">
                Enviar comunicações a <b>Você</b>;
              </span>
            </td>
            <td rowSpan="2">
              <span className="table-text justify-text">
                Art. 7º O tratamento de dados pessoais somente poderá ser
                realizado nas seguintes hipóteses:
              </span>
              <span className="table-text justify-text">
                I - mediante o fornecimento de consentimento pelo titular;
              </span>
              <span className="table-text justify-text">
                VII - para a proteção da vida ou da incolumidade física do
                titular ou de terceiro;
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="table-text">Dados de utilização</span>
            </td>
            <td>
              <span className="table-text">
                Enviar alertas de risco em determinados ambientes;
              </span>
              <span className="table-text">Criar estatísticas;</span>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <p>
        Para que todas as informações sejam corretamente compreendidas, nós
        apresentamos nossa Política de Privacidade de forma transparente e
        acessível, como poderá ser visto a seguir:
      </p>
      <br></br>
      <h4>
        DESTACAMOS QUE É EXTREMAMENTE IMPORTANTE A COMPREENSÃO COMPLETA E A
        LEITURA TOTAL DESTE DOCUMENTO
      </h4>
      <br></br>
      <h4>- TERMOS GERAIS -</h4>
      <ol>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Geral</u>.
              </b>{" "}
              Esta Política de Privacidade foi concebida para comunicar as
              nossas práticas relativas ao tratamento (inclui coleta,
              armazenamento, utilização, processamento e compartilhamento) de
              seus dados pessoais por Nós (doravante assim denominada ou somente
              “Plataforma”), que engloba o website e seus subdomínios,
              aplicativos, programas, painéis analíticos, dashboards, API de
              dados e demais extensões. A Plataforma é de propriedade da{" "}
              <b>Wiiglo Tecnologia da Informação LTDA.</b>, sociedade inscrita
              no CNPJ sob o nº 18.101.387/0001-39, com sede na Rua Fonseca
              Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200, e
              está sob sua responsabilidade e gestão.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>O que são Dados Pessoais</u>.
              </b>{" "}
              Os dados pessoais são aqueles informados por <b>Vocês</b>, os
              quais podem ser usados para identificá-los, individualmente.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>O que são Dados Pessoais Sensíveis</u>.
              </b>{" "}
              Dados pessoais sensíveis são aqueles sobre a origem racial ou
              étnica, convicção religiosa, opinião política, filiação a
              sindicato ou a organização de caráter religioso, filosófico ou
              político, dado referente à saúde ou à vida sexual, dado genético
              ou biométrico, quando vinculado a uma pessoa natural.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Autorização de Coleta de Informações</u>.
              </b>{" "}
              Ao utilizar a plataforma, Você deverá aceitar e autorizar a coleta
              de informações apenas para o cumprimento dos serviços ora
              contratados, através do botão “li e concordo com os termos”, nos
              termos da presente política de privacidade.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Ao utilizar a plataforma, <b>Você</b> poderá desistir de
                  consentir com as disposições desta Política de Privacidade, a
                  qualquer momento, bastando para tal que notifique a Cittua
                  através do e-mail <u>cittua@wiiglo.com</u>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Regulamentação</u>.
              </b>{" "}
              A Plataforma atua de acordo com a legislação brasileira,
              atendendo, inclusive e especialmente, com os termos da Lei nº.
              12.965/2014 (o Marco Civil da Internet) e da Lei nº 13.709/2018
              (Lei Geral de Proteção de Dados) e demais leis que venham a ser
              aplicadas.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Contato</u>.
              </b>{" "}
              Em caso de dúvida ou sugestões sobre a Política de Privacidade da
              Plataforma ou qualquer outra informação, Você poderá entrar em
              contato com os administradores através do e-mail{" "}
              <u>cittua@wiiglo.com</u>, que será respondido em horário
              comercial, de segunda-feira a sexta-feira, das 9h 30 min às 18h.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Controle de Informações</u>.
              </b>{" "}
              Esta Política descreve quais os dados pessoais podem ser coletados
              de <b>Vocês</b>, enquanto utilizam os serviços da Plataforma, como
              essas informações podem ser utilizadas e as precauções tomadas
              contra o acesso ou uso destas informações de forma não autorizada.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- DADOS PESSOAIS COLETADOS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>Nós</b> coletaremos suas informações e/ou dados pessoais quando{" "}
              <b>Você</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Fizer seu cadastro na <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Permitir integrações com suas redes sociais para realizar
                  cadastro ou log-in;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Utilizar funcionalidades da Plataforma que precisem de acesso
                  à sua geolocalização;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Responder perguntas feitas pela <b>Cittua</b> através da
                  Plataforma.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>Nós</b> poderemos coletar, armazenar e utilizar as seguintes
              informações e/ou dados pessoais de <b>Vocês</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Nome completo;
                </li>
                <li className="sub-item sub-item--alpha-count">Idade;</li>
                <li className="sub-item sub-item--alpha-count">E-mail;</li>
                <li className="sub-item sub-item--alpha-count">Endereço;</li>
                <li className="sub-item sub-item--alpha-count">Telefone;</li>
                <li className="sub-item sub-item--alpha-count">CPF;</li>
                <li className="sub-item sub-item--alpha-count">Gênero;</li>
                <li className="sub-item sub-item--alpha-count">
                  Geolocalização.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>Nós</b> poderemos coletar, ainda, os seguintes dados pessoais
              sensíveis de Vocês:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">Etnia;</li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>Nós</b> também poderemos coletar, armazenar e utilizar as
              seguintes informações:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Registro em banco de dados de quaisquer arquivos ou informação
                  carregados na <b>Nossa</b> Plataforma pelos titulares;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Os detalhes de acesso à Cittua e dos recursos que o titular
                  acessou;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Informações do dispositivo de acesso utilizado, incluindo, por
                  exemplo, modelo do hardware, sistema operacional e versão,
                  nomes e versões dos arquivos, idioma de preferência,
                  identificador exclusivo do dispositivo, identificadores de
                  publicidade, número de série, informações referentes ao
                  movimento do dispositivo e informações de rede;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Informações de registro do servidor que podem incluir
                  informações como endereço IP do dispositivo;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Mapeamento de cliques no navegador, dados de navegação,
                  estatísticos, demográficos, entre outros;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Mapeamento de cliques nos aplicativos e dados de navegação.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Aplicativos Nativos</u>.
              </b>{" "}
              Os aplicativos nativos podem armazenar dados de uso, imagens,
              vídeos, textos, senha de acesso ao sistema de biometria, os quais
              não são compartilhados <b>Conosco</b>.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- USO DOS DADOS PESSOAIS COLETADOS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              A utilização dos dados coletados se dará da seguinte forma:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Nome completo, idade, e-mail, endereço, telefone, CPF e gênero
                  serão utilizados para te identificar, validar seu acesso e
                  enviar comunicações a Você;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Os dados sobre sua geolocalização são usados para enviar
                  alertas de risco em determinados ambientes e para criar
                  estatísticas;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              A coleta de dados realizada pela <b>Cittua</b> tem como base legal
              o Art. 7º, I, VII e VIII Lei Geral de Proteção de Dados:
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Art. 7º O tratamento de dados pessoais somente poderá ser
                  realizado nas seguintes hipóteses: (i) I - mediante o
                  fornecimento de consentimento pelo titular; (ii) VII - para a
                  proteção da vida ou da incolumidade física do titular ou de
                  terceiro;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>Nós</b> nos limitamos a utilizar os dados pessoais coletados de{" "}
              <b>Vocês</b>, de forma anônimizada para fins estatísticos e de
              pesquisa. Os dados coletados de <b>Vocês</b> são usados para gerar
              conhecimento através dos nossos modelos matemáticos e estatísticos
              e para prover informações direcionadas a <b>Você</b> e dentro dos
              objetivos da Plataforma. Ao coletar um dado pessoal, a{" "}
              <b>Cittua</b> informa a <b>Você</b> o objetivo daquela coleta de
              dados específica.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- COMPARTILHAMENTO DOS DADOS PESSOAIS COLETADOS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Exceções para Compartilhamento de Dados Pessoais</u>.
              </b>{" "}
              <b>Nós</b> não divulgamos a terceiros qualquer dado pessoal
              fornecido por <b>Vocês</b>, através da <b>Nossa</b> Plataforma,
              exceto:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Casos em que <b>Nós</b> formos obrigados a divulgar ou
                  partilhar os dados coletados, a fim de cumprir ordem judicial,
                  ou para efeitos de prevenção de fraude ou outros crimes, bem
                  como em resposta à solicitação de informações apresentada por
                  autoridade competente, se entendermos que a divulgação está de
                  acordo ou é exigida conforme as leis, regulamentos ou
                  procedimentos processuais aplicáveis;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Para proteger os direitos, bens ou a segurança de <b>Nossa</b>{" "}
                  Plataforma;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Com agentes da lei e/ou autoridades governamentais, se
                  entender-se que suas ações são inconsistentes em relação às
                  disposições em nossos termos de uso, ou para proteger os
                  direitos, a propriedade ou a <b>Nossa</b> segurança, a de{" "}
                  <b>Vocês</b> ou de outros;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Mediante ação própria de <b>Vocês</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Nos casos de venda, parcial ou total, do negócio ou de seus
                  ativos, ou como parte de qualquer reorganização ou
                  reestruturação do negócio, fusão, cisão ou incorporação, de
                  modo que Nós poderemos compartilhar as informações de Vocês
                  com terceiros que façam parte dos respectivos negócios,
                  tomando as medidas necessárias para garantir que os direitos
                  de privacidade continuem a ser protegidos, conforme esta
                  Política;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Quando devidamente anonimizados após a criação de
                  estatísticas;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Autorizações de Contato e Divulgação</u>.
              </b>{" "}
              <b>Vocês</b> autorizam a <b>Cittua</b> a contatá-los utilizando
              qualquer um dos meios de contato informados no cadastro na
              Plataforma.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- COOKIES -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>O Que São os Cookies</u>.
              </b>{" "}
              Um <i>cookie</i> é um trecho de informação armazenado localmente
              no seu computador ou dispositivo, e que contém informação acerca
              das atividades deste na Internet.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cookies Utilizados</u>.
              </b>{" "}
              Os <i>cookies</i> podem ser usados de diferentes formas e
              modalidades. <b>Nós</b> poderemos utilizar:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Cookies de Desempenho</u>
                  </em>
                  : Este tipo de <i>cookie</i> coleta informações anônimas sobre
                  a forma como <b>Vocês</b> utilizam a plataforma, de forma a
                  otimizá-la. As informações coletadas por estes <i>cookies</i>{" "}
                  nunca contêm detalhes pessoais a partir dos quais seja
                  possível identificá-lo.
                </li>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Cookies Funcionais</u>
                  </em>
                  : Estes <i>cookies</i> são usados por <b>Nós</b> por algumas
                  de suas funcionalidades, buscando lhe oferecer uma melhor
                  experiência ao navegar pela plataforma.
                </li>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Cookies Publicitários</u>
                  </em>
                  : Estes <i>cookies</i> coletam informações sobre os hábitos de
                  navegação, buscando tornar a publicidade mais relevante para{" "}
                  <b>Você</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Acesso aos Cookies</u>.
              </b>{" "}
              O acesso aos <i>cookies</i> termina assim que <b>Você</b> fecha o
              navegador. É dada a <b>Você</b> a possibilidade de aceitar ou
              recusar os <i>cookies</i>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Consentimento para Utilização de Cookies</u>.
              </b>{" "}
              É necessária sua aceitação, antes de iniciada a seção, para que os{" "}
              <i>Cookies</i> sejam utilizados.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Opção sem Cookies</u>.
              </b>{" "}
              O titular pode optar por recusar o uso de <i>cookies</i>. Se{" "}
              <b>Você</b> optar por não os aceitar, o seu acesso à maior parte
              das informações disponíveis na Plataforma não será comprometido.
              Contudo, o titular poderá não conseguir usar plenamente os
              serviços oferecidos.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- ACESSO E CORREÇÃO DE DADOS PESSOAIS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Acesso e Retificação de Dados Pessoais</u>.
              </b>{" "}
              <b>Vocês</b> têm direito a acessar os seus dados pessoais sob{" "}
              <b>Nossa</b> posse, contatando o Encarregado de Proteção de Dados
              (DPO) através dos meios de contato dispostos na cláusula 8.5, que
              será respondido em horário comercial, de segunda-feira a
              sexta-feira, das 9h 30 min às 18h, no prazo de 15 (quinze) dias,
              cumulada com descrição da forma, finalidade e duração do
              tratamento dos dados pessoais, que poderá ser enviada por e-mail
              ou através de carta, de acordo com o previsto no artigo 9º da
              LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Exclusão</u>.
              </b>{" "}
              <b>Você</b> tem direito de solicitar a exclusão dos seus dados
              pessoais armazenados na <b>Cittua</b>, a qualquer momento, salvo
              nos casos em que houver obrigação legal ou decisão judicial para a
              manutenção do armazenamento dos dados, de acordo com o disposto
              nos artigos 18, XVI, e 16 da LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Incorreção de Informações</u>.
              </b>{" "}
              É responsabilidade do titular manter suas informações atualizadas,
              sendo certo que, no caso de incorreção, <b>Nós</b> poderemos
              atualizá-las ou excluí-las, excetuando-se os casos de necessidade
              de manutenção para fins comerciais legítimos ou jurídicos.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Medidas Necessárias de Proteção</u>.
              </b>{" "}
              <b>Nós</b> tomamos as medidas de segurança necessárias para
              proteger seus dados pessoais contra a perda, utilização indevida,
              acesso, divulgação, alteração ou destruição não autorizados.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Proteção de Senhas</u>.
              </b>{" "}
              <b>Vocês</b> são igualmente responsáveis por tomar medidas
              cabíveis destinadas a proteger as suas senhas, nomes de usuário e
              outros recursos de acesso especiais ao cadastro pessoal na{" "}
              <b>Cittua</b>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- NOTIFICAÇÃO DE MODIFICAÇÕES NA POLÍTICA DE PRIVACIDADE -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Alterações na Política de Privacidade</u>.
              </b>{" "}
              <b>Nós</b> podemos alterar a Política de Privacidade da Plataforma
              de tempos em tempos. O uso de informações coletadas agora está
              sujeito à Política de Privacidade em vigor. Se <b>Nós</b>{" "}
              realizarmos mudanças na forma como utiliza as informações
              pessoais, notificaremos <b>Vocês</b> através do envio de e-mail,
              do site ou do aplicativo.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Ajustes Não Notificados</u>.
              </b>{" "}
              Pequenos ajustes nesta Política de Privacidade podem ocorrer sem
              afetar significativamente as formas em que Nós tratamos as
              informações coletadas, de forma que estes não precisarão ser
              informados.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- COMUNICAÇÃO -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Envio de Comunicações</u>.
              </b>{" "}
              <b>Vocês</b>, ao se cadastrarem, concordam que Nós enviemos para o
              seu <i>e-mail</i> notificações, publicidades, novidades sobre os
              serviços e informações importantes sobre a utilização da
              Plataforma que necessitem da sua atenção.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>
                    <u>Opção por Não Recebimento</u>.
                  </b>{" "}
                  Ao receber um <i>e-mail</i> em nome da Plataforma, haverá a
                  possibilidade de optar por não mais recebê-los, utilizando da
                  opção opt-out ou através de solicitação por <i>e-mail</i>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Política AntiSpam</u>.
              </b>{" "}
              A Plataforma toma os cuidados necessários para evitar o envio não
              solicitado de <i>e-mails</i>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Confidencialidade</u>.
              </b>{" "}
              É assegurado o máximo de confidencialidade no tratamento da lista
              de telefones e <i>e-mails</i> durante as tarefas regulares de{" "}
              <b>Nossa</b> administração.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- SEGURANÇA DE DADOS PESSOAIS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Armazenamento</u>.
              </b>{" "}
              Os dados pessoais de sua conta e todas as informações da
              plataforma são armazenados e trafegam de forma segura em
              servidores da Amazon Web Services (AWS) nos Estados Unidos da
              América, sendo que apenas os colaboradores autorizados por Nós
              podem ter acesso às suas informações pessoais, estando
              obrigatoriamente sujeitos aos deveres de confidencialidade e
              rigoroso respeito à sua privacidade nos termos desta Política.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Segurança nos Dados</u>.
              </b>{" "}
              Todos os dados pessoais coletados por <b>Nós</b> sobre <b>Você</b>{" "}
              são manipulados com segurança e confidencialidade, incluindo a
              transmissão usando criptografia moderna (HTTPS).
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Segurança da Informação</u>.
              </b>{" "}
              Todas as transações da Cittua são criptografadas, sujeitas a
              backup de dados, ferramentas de monitoramento, políticas de
              segurança, controles de acesso para colaboradores, com softwares
              de segurança atualizados.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Notificação</u>.
              </b>{" "}
              Se <b>Nós</b> tomarmos conhecimento de qualquer violação à
              segurança própria ou a empresas de hospedagem, incluindo invasões,
              vazamentos de dados ou qualquer outro incidente de segurança da
              informação, iremos notificar as autoridades nacionais,{" "}
              <b>Vocês</b>, se afetados, acerca dessa violação e iremos fornecer
              o máximo de detalhes referente à natureza, extensão da violação e
              os dados comprometidos, em prazo razoável, de acordo com o
              disposto nos artigos 48, § 1º, da LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Prazo de Armazenamento</u>.
              </b>{" "}
              <b>Nós</b> armazenaremos seus dados durante todo o período em que
              seu cadastro estiver ativo.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Encarregado de Proteção de Dados</u>.
              </b>{" "}
              Fica definido que Daniel Morim será o Encarregado de Proteção de
              Dados da Cittua/Wiiglo e poderá ser contatado através do{" "}
              <i>e-mail</i> <u>daniel@wiiglo.com</u>, ou à Rua Fonseca Teles,
              121, São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- DISPOSIÇÕES GERAIS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Responsabilidade do Usuário</u>.
              </b>{" "}
              <b>Vocês</b> serão responsáveis por <b>Nos</b> indenizar por todos
              os custos e danos que este possa incorrer, como resultado da
              violação desta Política de Privacidade por si provocados.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cooperação com Autoridades</u>.
              </b>{" "}
              <b>Nós</b> cooperamos plenamente com quaisquer autoridades ou
              tribunais que venham a solicitar a divulgação da identidade ou
              localização de qualquer pessoa que tenha inserido qualquer
              material na Plataforma que violem as Cláusulas dispostas nesta
              Política.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Limitação de Responsabilidade</u>.
              </b>{" "}
              Esta Política de Privacidade aborda apenas o uso e divulgação de
              informações coletadas pela Nossa Plataforma. Se Vocês divulgarem
              suas informações a websites de terceiros, regras diferentes podem
              ser aplicadas à utilização das suas informações.
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}

function TermsTextEn() {
  return (
    <>
      <p>Dear</p>
      <p>
        These Terms of Use will govern the relationship between <b>Cittua</b>{" "}
        and <b>You</b>.
      </p>
      <p>
        To use the Platform's services, <b>You</b> must agree and accept the
        Terms of Use and Privacy Policy.
      </p>
      <br></br>
      <h4>- GENERAL CONDITIONS -</h4>
      <ol>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Scope</u>.
              </b>{" "}
              These General Terms and Conditions of Use (referred to as “Terms
              of Use” or “Terms”) bind all activities developed and services
              offered by <b>We</b> (hereinafter so-called or just “Platform”),
              which encompasses the website and its subdomains, applications,
              programs, data API and others extensions. The Platform is owned by{" "}
              <b>Wiiglo Tecnologia da Informação LTDA.</b>, registered company
              with CNPJ under nº 18.101.387/0001-39, with headquarters at Rua
              Fonseca Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP
              20940-200, and is under your responsibility and management.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Performance</u>.
              </b>{" "}
              <b>We</b> are a Platform that offers the service of classification
              of risks of a given environment based on the data analysis.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Subjects</u>.
              </b>{" "}
              We will use the expression <b>“You”</b> to refer to the users of
              the Platform, understood as any person who use <b>Cittua</b>{" "}
              resources.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Membership and Acceptance</u>.
              </b>{" "}
              By this instrument, <b>You</b> agree that:
              <ol>
                <br></br>
                <li className="sub-item sub-item--roman-count">
                  You must accept our Terms of Use and Policy Privacy, for
                  registration purposes, access control and carrying out
                  improvements to the Platform;
                </li>
                <li className="sub-item sub-item--roman-count">
                  By clicking on the Terms acceptance box, they must adhere and
                  agree to be bound by the provisions of these Terms;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Violation of Conditions</u>.
              </b>{" "}
              In the event that <b>You</b> fail to comply with any of the
              conditions provided here, <b>We</b> reserve the right to terminate
              their respective accounts and suspend or terminate their access to
              Platform, without the need for prior notice.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Modifications</u>.
              </b>{" "}
              <b>We</b> reserve the right, at any time, to modify the Terms of
              Use, always sending notices to your science, without any liability
              on the part of <b>Cittua</b> due to:
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Any modifications made by <b>Us</b> to the platform, which
                  will come into force immediately after publication{" "}
                  <em>online</em>.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- HOW TO USE -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Dynamics</u>.
              </b>{" "}
              <b>You</b> will be able to register on <b>Our</b> Platform
              providing the data as provided in the Privacy Policy or through
              your Facebook or Google account. Once registered, will be able to
              access the Platform and use all its functionalities.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Incorrect Registration Information</u>.
              </b>{" "}
              <b>You</b>, to use the Platform Services, must provide the
              information required at the time of registration, as per set out
              in the Privacy Policy.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>We</b> reserve the right to prevent <b>Our</b> criteria,
                  new registrations, or cancel those already made, in If an
                  anomaly is detected that you consider, in <b>Our</b> analysis,
                  covered by a serious error or demonstrates an attempt
                  deliberately circumventing the rules described here, mandatory
                  for all <b>You</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Third Party Registration</u>.
              </b>{" "}
              Registrations on the platform are individual and non-transferable,
              <b>Cittua</b> must be immediately notified of any unauthorized use
              of your account, in a timely manner, not
              <b>We</b> being responsible for any losses or damages arising from
              the use of “login” and password by third parties, with or without
              the user consent.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>You</b> must: (i) Be careful with your data individual
                  identification whenever they access the Internet; (ii) Take
                  other necessary measures to protect themselves from harm,
                  including online and offline fraud; (iii) Be diligent in use
                  of content available on <b>Our</b> Platform, knowing their
                  responsibilities; and (iv) Comply strictly comply with all
                  provisions of these Terms of Use.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Your Obligations</u>.
              </b>{" "}
              It is <b>You</b>'s obligation:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Do not use <b>Our</b> Platform for any purpose that is
                  unlawful or prohibited by the terms of use and/or the
                  applicable standards;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Authorize <b>We</b> to send communications via email,
                  telephone or instant messaging, including advertising
                  character;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Present honest and good faith behavior;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Correctly and accurately inform all your data, to registration
                  purposes.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Platform Obligations</u>.
              </b>{" "}
              It is <b>Our</b> obligation:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Make the Platform services available in a safe and secure
                  manner stable;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Maintain, according to <b>Our</b> judgment and possibilities
                  of market, constant updating of the platform aiming at its
                  improvement and adaptation to new available technologies;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Protect the confidentiality of all information
                  <b>You</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Suitability</u>.
              </b>{" "}
              <b>You</b> declare, by consenting to this document to use the
              Platform, which is fully reputable, remaining aware, from now on,
              that false or false registration information untrue information
              recorded or provided during the process electronic contracting of
              services may constitute a crime.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Prohibitions</u>.
              </b>{" "}
              It is prohibited for <b>You</b> to:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Use any virus, worm, Trojan horse, time bomb, spyware or other
                  computer code, file or program that is harmful or invasive or
                  which may be or is intended to damage, hijack, disable,
                  interfere com, or disrupt the operation of, or monitor the use
                  of any hardware, software or equipment;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Use any material that gives rise to liability civil or
                  criminal, which encourages conduct that constitutes a criminal
                  offense or that encourages or provides information information
                  about illegal activities or activities such as hacking and
                  cracking;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Use the Platform for any illegal or fraudulent;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Interfere with or disrupt the operation of the Platform or
                  violate any requirements, procedures, policies or regulations
                  from <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Restrict or inhibit any other person from using the Platform;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Remove any copyright, trademark notice commercial or other
                  proprietary rights of <b>Cittua</b> or materials originating
                  from the Platform;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Create a database by transferring and storing Content from the
                  Platform;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Use data/information made available on the Platform in other
                  environments without due reference to <b>Cittua</b>.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- ACCESS AND FUNCTIONALITY RESTRICTIONS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Restrictions</u>.
              </b>{" "}
              It is not permitted to access <b>Our</b> programming areas, your
              database or any other set of information that makes part of the
              webmastering activity, carry out or allow to be perform any type
              of reverse engineering, translation, decompilation, copying,
              modification, reproduction, rental, subletting, sublicensing,
              publication, dissemination, transmission, loan, distribution or
              otherwise the disposal of consultation tools for this website and
              its features to third parties without our prior and express
              authorization, Whoever does so is subject to penalties under
              Brazilian legislation, without prejudice to the obligation to
              repair the damage they cause. It is restriction includes any
              attempt to incorporate any platform information in any other
              directory, product or service.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- REMUNERATION AND CANCELLATION -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Remuneration</u>.
              </b>{" "}
              To use the features offered by the Platform, <b>You</b> will not
              need to make any consideration pecuniary
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cancellation of Registration</u>.
              </b>{" "}
              If <b>You</b> cancel your registration on the Platform, all of
              your records will be deleted, except in cases where there is need
              to retain records to comply with legal or regulatory obligation.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- EXCLUSION OF WARRANTIES -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Availability</u>.
              </b>{" "}
              The platform is available for use by <b>You</b> and is offered on
              an "as is" and "as available" basis. <b>We</b> decline any
              liability for warranties implied terms of merchantability, fitness
              for a particular purpose and of financial return. Furthermore,{" "}
              <b>We</b> do not guarantee that services will be provided without
              interruptions or errors. <b>You</b> accept that the entire risk
              arising from the use of the Our Platform is your sole
              responsibility.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- LIMITATION OF LIABILITY -</h4>
        <li className="invisible-item list-item">
          <ol>
            <br></br>
            <li className="list-item">
              <b>We</b> only act as an intermediary between <b>You</b> and we
              will be responsible exclusively and limitedly for the services
              provided.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>We</b> reiterate that we do not constitute any of{" "}
                  <b>You</b>: (i) company, association or representation; (ii)
                  provision of any service other than the purpose of this
                  private instrument, and/or (iii) employment relationship.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Responsibility</u>.
              </b>{" "}
              <b>We</b> will not be responsible for any loss or damage suffered
              as a result of: (i) any incorrect information or incomplete that
              is provided by <b>You</b>; (ii) any fraud, fraudulent
              misrepresentation or breach of duty, or breach of any of the
              conditions by <b>You</b>; (iii) failures in connections; (iv)
              problems in data processing due to the 3rd; (v) third-party
              products or services, even if acquired through the Platform.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>We</b> provide <b>You</b> with information regarding
                  probability of a certain event happening at a certain time
                  location, there are chances that the probabilities will not
                  materialize. Therefore, we are not responsible for possible
                  inaccuracies in the information provided through the Platform.
                </li>
                <li className="sub-item list-item">
                  Likewise, <b>We</b> are not responsible for events not
                  notified by the Platform that may occur.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Violation of Third Party Rights</u>.
              </b>{" "}
              <b>We</b> are not responsible to <b>You</b> for any in relation to
              any violation of third party rights.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Damage</u>
              </b>{" "}
              In no event will <b>We</b> be liable for damages indirect,
              incidental, special, exemplary, punitive or consequential,
              including, personal injury or property damage, relating to, or
              otherwise resulting from, any use of the platform that <b>You</b>{" "}
              may suffer due to the actions carried out or not carried out
              through this, even if resulting the conduct of third parties.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Indemnity</u>.
              </b>{" "}
              <b>You</b> will indemnify <b>Cittua</b>, directors,
              administrators, collaborators, representatives and employees by
              any demand promoted by third parties arising from their activities
              on the Platform, and for non-compliance with the terms of use and
              conditions and other policies thereof, and also for the violation
              of any law or rights of third parties, including fees lawyers
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- INACTIVATION OF REGISTRATION, QUESTIONS AND SUGGESTIONS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Registration Inactivation, Questions and Suggestions</u>.
              </b>{" "}
              In case of account inactivation, questions or suggestions about
              our Terms of Use or any other information, <b>You</b> may contact
              us through the space provided for user service on the Platform.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Sealing and Deactivating Registration</u>.
              </b>{" "}
              As a way of maintaining trust and respect on this Platform,
              illegal acts or acts incompatible with the proposal of ensure the
              provision of quality services. Thus, you can result in suspension
              or inactivation of your registration if <b>You</b> incur any of
              the hypotheses listed below:
              <ol>
                <br></br>
                <li className="sub-item sub-item--roman-count">
                  Promotion of issue-based violence and/or discrimination race,
                  sex, religion, nationality, sexual orientation or of any other
                  type;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Violation of which laws, especially regarding copyright,
                  intellectual property, such as copies unauthorized use of
                  images, sounds, movements or texts without authorization from
                  the author, be they trademarks, replicas and/or forgeries;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Violation of the provisions of the Platform's Privacy Policy;
                </li>
                <li className="sub-item sub-item--roman-count">
                  Sharing of incomplete or different data than made available by{" "}
                  <b>Cittua</b>;
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- PLATFORM ENGINEERING -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Improvement</u>.
              </b>{" "}
              <b>You</b> agree that any and all systems, platforms or service,
              regardless of its producer or characteristics, is a kind of
              product that is always updated and improvement.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Operating Errors</u>.
              </b>{" "}
              Any errors in the functioning of the Platform will be corrected as
              soon as possible. as soon as possible, for as long as necessary to
              maintenance. <b>We</b> do not intend that the operation of the
              Platform server, as well as its system, its database data,
              software and your website, are free from errors, failures or of
              interruptions.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- INTELLECTUAL PROPERTY -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Use of Intellectual Property</u>.
              </b>{" "}
              The commercial use of the expression <b>"Cittua"</b>, as a brand,
              name business or domain name, and the logo are the property
              exclusive to Our owners, as provided in the respective
              constitutive act of the organization. In the same way, copyright
              and other rights subject to protection by industrial property as a
              structure, the contents of the screens relating to the Platform,
              as well as programs related to itself, software, website,
              databases, networks and files. All rights are protected, in Brazil
              and internationally, by international laws and treaties on
              copyright, trademarks, patents, models and industrial designs. To
              Our Owners reserve all rights relating to authorship, trademarks,
              patents, models and industrial designs, whether they are yours
              owned or licensed in your favor, to you.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  It is not permitted to reproduce, duplicate, copy, sell,
                  resell, visit, or otherwise exploit for commercial purposes
                  the content of the Platform without prior written consent from
                  Our owners. In particular, the use of data mining, robots, or
                  other data collection and data extraction to extract, in
                  isolation or applicant, any substantial part of the Platform
                  aimed at its reuse.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Relations with Third Parties</u>.
              </b>{" "}
              The Platform may provide access links to other websites, which
              which does not mean that these sites are owned or operated by{" "}
              <b>Us</b>. As we have no control over these sites, <b>We</b> will
              not be responsible for the content, practices and services offered
              therein. The presence of links to others sites does not imply a
              relationship of partnership, supervision, complicity or solidarity
              of <b>Cittua</b> with these websites and their content.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- FINAL CONDITIONS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Suspension and Cancellation of Access</u>.
              </b>{" "}
              In the event of non-compliance with any provision of the Terms of
              Use, <b>You</b> acknowledge and accept that <b>We</b> We may,
              without prior notice, interrupt or suspend, temporarily or
              permanently, part or all, of access to the platform.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Warnings and Suspension</u>.
              </b>{" "}
              Without prejudice to other applicable measures, <b>We</b> may
              warn, suspend, temporarily or definitively, your access to, at any
              time, if:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  fails to comply with any provision of the terms of use or the
                  Privacy Policy;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  fail to comply with any of its obligations or prohibitions
                  assumed when using the platform's services;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  use, without authorization, the name and/or brand of{" "}
                  <b>Cittua</b>, in part or in whole, and violate copyright
                  intellectual property of <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  identity cannot be verified or if any information provided by
                  you is incorrect;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  share incomplete or different data than made available by{" "}
                  <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  share data/information made available on the Platform in other
                  environments without due reference to <b>Cittua</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Processing of Personal Data</u>.
              </b>{" "}
              So that the Platform has quality and so that <b>You</b> can obtain
              results quickly and safely, it is necessary to provision of
              personal data, under the terms set out in the Policy
              <b>Cittua</b> Privacy Policy.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Nullities</u>.
              </b>{" "}
              If any provision of this instrument is considered illegal, invalid
              or inapplicable, in whole or in part, under any law, this clause
              or part thereof will be in this exact measure understood as not
              being part of the Terms of Use, being that the legality, validity
              and applicability of the other clauses will not be affected.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- APPLICABLE LAW AND JURISDICTION -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              These Terms of Use are governed by Brazilian laws, having defined
              the jurisdiction of the Central District of Rio de Janeiro as
              competent to resolve any disputes arising of these, to the
              detriment of any other, however privileged whether or will be, and
              priority must always be given to the attempts at resolution
              through conciliation or mediation.
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}

function PrivacyTextEn() {
  return (
    <>
      <p>Welcome to!</p>
      <br></br>
      <p>We are very happy to choose the Cittua Platform!</p>
      <br></br>
      <p>
        If you are in a hurry to access the Platform, we provide a brief summary
        in the table below.
      </p>
      <br></br>
      <div className="frame">
        <h4>RIGHTS OF PERSONAL DATA SUBJECTS</h4>
        <br></br>
        <p>
          <b>You</b> are the holders (natural persons) of personal data to who
          the personal data that will be collected by <b>Us</b> refers to.
        </p>
        <br></br>
        <p>
          Brazilian legislation grants certain rights to Individuals regarding
          to your personal data. In this way, we guarantee transparency and
          control of access to holders of personal data, providing them with the
          best possible experience during use of the Platform. The rights of the
          holder of personal data are following:
        </p>
        <br></br>
        <ol>
          <li className="item item--alpha-count">
            Right to confirm the existence of treatment - Right to request
            confirmation of the existence of processing of your data personal
            information through clear information about the origin of the data,
            the lack of registration, the criteria used and the purpose of the
            treatment;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right of access - right to be informed and request access to
            personal data processed by <b>We</b>;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right to rectification - right to request that <b>Us</b> change or
            update your personal data when it are incorrect or incomplete;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right to deletion - right to request the deletion of your personal
            data collected by <b>Us</b>;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right of restriction - right to request that <b>We</b> leave,
            temporarily or permanently, to process all or some of your personal
            data;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right of opposition – right to oppose, at any time, (i) the
            processing of your personal data for reasons related to your
            particular situation and (ii) the handling of your personal data for
            direct marketing purposes;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right to data portability - right to request a copy of your personal
            data in electronic format and/or transmit the said personal data for
            use in the third party service;
          </li>
          <br></br>
          <li className="item item--alpha-count">
            Right not to be subject to automated decisions - right not to submit
            to decisions made in a solely automated way, including with regard
            to profiling, if the decision has a legal effect on You or produces
            an effect equally significant.
          </li>
        </ol>
      </div>
      <br></br>
      <table>
        <thead>
          <tr className="table-header">
            <th colSpan="3">
              <h5>INFORMATION / PERSONAL DATA COLLECTED AND ITS PURPOSE</h5>
              <span className="table-text">
                In the table below we show which data will be collected, its
                category and purpose.
              </span>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>
              <h6>DATA COLLECTED</h6>
            </th>
            <th colSpan="2">
              <h6>CLASSIFICATION</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="table-text">Full name;</span>
              <span className="table-text">Age;</span>
              <span className="table-text">Email;</span>
              <span className="table-text">Address;</span>
              <span className="table-text">Phone;</span>
              <span className="table-text">CPF;</span>
              <span className="table-text">Gender;</span>
              <span className="table-text">Ethnicity;</span>
            </td>
            <td colSpan="2">
              <span className="table-text">Registration data</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="table-text">Geolocation;</span>
            </td>
            <td colSpan="2">
              <span className="table-text">Usage data</span>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <span className="table-text">
                The collected data will be used with the following purposes:
              </span>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>
              <h6>DATA COLLECTED</h6>
            </th>
            <th>
              <h6>GOAL</h6>
            </th>
            <th>
              <h6>LEGAL BASE</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="table-text">Registration data</span>
            </td>
            <td>
              <span className="table-text">Identify you;</span>
              <span className="table-text">Validate your access;</span>
              <span className="table-text">
                Send communications to <b>You</b>;
              </span>
            </td>
            <td rowSpan="2">
              <span className="table-text justify-text">
                Art. 7 The processing of personal data may only be carried out
                in the following cases:
              </span>
              <span className="table-text justify-text">
                I - upon provision of consent by the holder;
              </span>
              <span className="table-text justify-text">
                VII - to protect the life or physical safety of the holder or
                third party;
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="table-text">Usage data</span>
            </td>
            <td>
              <span className="table-text">
                Send risk alerts in certain environments;
              </span>
              <span className="table-text">Create statistics;</span>
            </td>
          </tr>
        </tbody>
      </table>
      <br></br>
      <p>
        So that all information is correctly understood, we we present our
        Privacy Policy in a transparent and accessible, as can be seen below:
      </p>
      <br></br>
      <h4>
        WE EMPHASIZE THAT IT IS EXTREMELY IMPORTANT TO COMPLETE UNDERSTANDING
        AND READ THIS DOCUMENT IN FULL
      </h4>
      <br></br>
      <h4>- GENERAL TERMS -</h4>
      <ol>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>General</u>.
              </b>{" "}
              This Privacy Policy is designed to communicate the our practices
              relating to processing (includes collection, storage, use,
              processing and sharing) of your personal data by Us (hereinafter
              referred to as “Platform”), which encompasses the website and its
              subdomains, applications, programs, analytical panels, dashboards,
              API data and other extensions. The Platform is owned by{" "}
              <b>Wiiglo Tecnologia da Informação LTDA.</b>, registered company
              with CNPJ under nº 18.101.387/0001-39, with headquarters at Rua
              Fonseca Teles, 121, São Cristóvão, Rio de Janeiro/RJ, CEP
              20940-200, and is under your responsibility and management.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>What are Personal Data</u>.
              </b>{" "}
              Personal data are those provided by <b>You</b>, the which can be
              used to identify them individually.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>What is Sensitive Personal Data</u>.
              </b>{" "}
              Sensitive personal data are those about racial origin or
              ethnicity, religious conviction, political opinion, affiliation to
              union or organization of a religious, philosophical or political,
              data relating to health or sexual life, genetic data or biometric,
              when linked to a natural person.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Information Collection Authorization</u>.
              </b>{" "}
              When using the platform, You must accept and authorize the
              collection of information only for the fulfillment of the services
              now contracted, through the button “I read and agree to the
              terms”, in the terms of this privacy policy.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  When using the platform, <b>You</b> may opt out of consent to
                  the provisions of this Privacy Policy, the at any time, simply
                  by notifying Cittua via email <u>cittua@wiiglo.com</u>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Regulation</u>.
              </b>{" "}
              The Platform operates in accordance with Brazilian legislation, in
              compliance, including and especially, with the terms of Law no.
              12,965/2014 (the Internet Civil Framework) and Law No. 13,709/2018
              (General Data Protection Law) and other laws that may be applied.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Contact</u>.
              </b>{" "}
              If you have any questions or suggestions about the Privacy Policy
              of Platform or any other information, You may enter Contact
              administrators via email <u>cittua@wiiglo.com</u>, which will be
              responded to promptly commercial, from Monday to Friday, from 9:30
              am to 6:00 pm.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Information Control</u>.
              </b>{" "}
              This Policy describes what personal data may be collected of{" "}
              <b>You</b>, while using the Platform's services, such as This
              information can be used and precautions taken against unauthorized
              access or use of this information.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- PERSONAL DATA COLLECTED -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>We</b> will collect your information and/or personal data when{" "}
              <b>You</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Register with <b>Cittua</b>;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Allow integrations with your social networks to carry out
                  registration or login;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Use Platform features that require access your geolocation;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Answer questions asked by <b>Cittua</b> through Platform.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>We</b> may collect, store and use the following information
              and/or personal data of <b>You</b>:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">Full name;</li>
                <li className="sub-item sub-item--alpha-count">Age;</li>
                <li className="sub-item sub-item--alpha-count">Email;</li>
                <li className="sub-item sub-item--alpha-count">Address;</li>
                <li className="sub-item sub-item--alpha-count">Phone;</li>
                <li className="sub-item sub-item--alpha-count">CPF;</li>
                <li className="sub-item sub-item--alpha-count">Gender;</li>
                <li className="sub-item sub-item--alpha-count">Geolocation.</li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>We</b> may also collect the following personal data sensitive
              of you:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">Ethnicity;</li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>We</b> may also collect, store and use following information:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Database registration of any files or information uploaded to
                  the <b>Our</b> Platform by the holders;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Details of access to Cittua and the resources that the holder
                  accessed;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Information about the access device used, including, for
                  example, example, hardware model, operating system and
                  version, file names and versions, preferred language, unique
                  device identifier, advertising, serial number, information
                  relating to the device movement and network information;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Server log information that may include information such as
                  the device's IP address;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Mapping of browser clicks, browsing data, statistical,
                  demographic, among others;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Mapping of application clicks and navigation data.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Native Applications</u>.
              </b>{" "}
              Native apps can store usage data, images, videos, texts, password
              to access the biometrics system, which are not shared{" "}
              <b>With Us</b>.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- USE OF COLLECTED PERSONAL DATA -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              The collected data will be used as follows:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Full name, age, email, address, telephone number, CPF and
                  gender will be used to identify you, validate your access and
                  send communications to You;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Data about your geolocation is used to send risk alerts in
                  certain environments and to create Statistics;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              The data collection carried out by <b>Cittua</b> has a legal basis
              o Art. 7, I, VII and VIII General Data Protection Law:
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  Art. 7 The processing of personal data may only be carried out
                  in the following cases: (i) I - through the provision of
                  consent by the holder; (ii) VII - for the protection of the
                  life or physical safety of the holder or third;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>We</b> limit ourselves to using the personal data collected
              from <b>You</b>, anonymously for statistical and information
              purposes. search. The data collected from <b>You</b> is used to
              generate knowledge through our mathematical and statistical models
              and to provide information directed to <b>You</b> and within the
              objectives of the Platform. When collecting personal data,{" "}
              <b>Cittua</b> informs <b>You</b> of the purpose of collecting
              specific data.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- SHARING OF COLLECTED PERSONAL DATA -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Exceptions for Sharing Personal Data</u>.
              </b>{" "}
              <b>We</b> do not disclose any personal data to third parties
              provided by <b>You</b>, through <b>Our</b> Platform, except:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  Cases in which <b>We</b> are required to disclose or share the
                  collected data in order to comply with a court order, or for
                  the purposes of preventing fraud or other crimes, as well as
                  as in response to the request for information presented by
                  competent authority, if we understand that the disclosure is
                  in accordance with agreement or is required under laws,
                  regulations or applicable procedural procedures;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  To protect the rights, property or safety of <b>Our</b>{" "}
                  Platform;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  With law enforcement and/or government authorities, if
                  understanding that their actions are inconsistent with the
                  provisions in our terms of use, or to protect rights, property
                  or <b>Our</b> security, that of <b>You</b> or others;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  Through <b>You</b>'s own action;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  In cases of sale, partial or total, of the business or its
                  assets, or as part of any reorganization or business
                  restructuring, merger, spin-off or incorporation, of How We
                  Can Share Your Information with third parties that are part of
                  the respective businesses, taking the necessary measures to
                  ensure that the rights of privacy continue to be protected, in
                  accordance with this Policy;
                </li>
                <li className="sub-item sub-item--alpha-count">
                  When properly anonymized after the creation of Statistics;
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Contact and Disclosure Authorizations</u>.
              </b>{" "}
              <b>You</b> authorize <b>Cittua</b> to contact you using any of the
              contact methods provided when registering on the Platform.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- COOKIES -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>What Are Cookies</u>.
              </b>{" "}
              A <i>cookie</i> is a piece of information stored locally on your
              computer or device, and which contains information about of its
              activities on the Internet.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cookies Used</u>.
              </b>{" "}
              <i>cookies</i> can be used in different ways and modalities.{" "}
              <b>We</b> may use:
              <ol>
                <br></br>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Performance Cookies</u>
                  </em>
                  : This type of <i>cookie</i> collects anonymous information
                  about the way <b>You</b> use the platform, in order to
                  optimize it. The information collected by these <i>cookies</i>{" "}
                  never contain personal details from which any possible to
                  identify it.
                </li>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Functional Cookies</u>
                  </em>
                  : These <i>cookies</i> are used by <b>Us</b> for some of its
                  functionalities, seeking to offer you a better experience when
                  browsing the platform.
                </li>
                <li className="sub-item sub-item--alpha-count">
                  <em>
                    <u>Advertising Cookies</u>
                  </em>
                  : These <i>cookies</i> collect information about your browsing
                  habits navigation, seeking to make advertising more relevant
                  to <b>You</b>.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Access to Cookies</u>.
              </b>{" "}
              Access to <i>cookies</i> ends as soon as <b>You</b> closes the
              browser. <b>You</b> are given the possibility to accept or refuse{" "}
              <i>cookies</i>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Consent to the Use of Cookies</u>.
              </b>{" "}
              Your acceptance is necessary before starting the section, so that{" "}
              <i>Cookies</i> are used.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cookie-free option</u>.
              </b>{" "}
              The holder may choose to refuse the use of <i>cookies</i>. If{" "}
              <b>You</b> choose not to accept them, your access to most of the
              information available on the Platform will not be compromised.
              However, the holder may not be able to fully use the services
              offered.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- ACCESS AND CORRECTION OF PERSONAL DATA -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Access and Rectification of Personal Data</u>.
              </b>{" "}
              <b>You</b> have the right to access your personal data under{" "}
              <b>Our</b> possession, contacting the Data Protection Officer
              (DPO) through the contact methods set out in clause 8.5, which
              will be answered during business hours, from Monday to Friday,
              from 9:30 am to 6:00 pm, within 15 (fifteen) days, combined with a
              description of the form, purpose and duration of the processing of
              personal data, which may be sent by email or by letter, in
              accordance with the provisions of article 9 of the LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Exclusion</u>.
              </b>{" "}
              <b>You</b> have the right to request the deletion of your data
              personal information stored in <b>Cittua</b>, at any time, except
              in cases where there is a legal obligation or court decision to
              maintenance of data storage, in accordance with the provisions in
              articles 18, XVI, and 16 of the LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Incorrect Information</u>.
              </b>{" "}
              It is the holder's responsibility to keep their information up to
              date, being certain that, in the event of an error, <b>We</b> may
              update or delete them, except when necessary maintenance for
              legitimate business or legal purposes.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Necessary Protection Measures</u>.
              </b>{" "}
              <b>We</b> take the necessary security measures to protect your
              personal data against loss, misuse, unauthorized access,
              disclosure, alteration or destruction.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Password Protection</u>.
              </b>{" "}
              <b>You</b> are equally responsible for taking appropriate action
              measures designed to protect your passwords, usernames and other
              special access features to personal registration at <b>Cittua</b>
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- NOTIFICATION OF CHANGES TO THE PRIVACY POLICY -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Changes to Privacy Policy</u>.
              </b>{" "}
              <b>We</b> may change the Platform's Privacy Policy from time to
              time. The use of information collected is now subject to the
              current Privacy Policy. If <b>We</b> make changes to the way you
              use information personal information, we will notify <b>You</b> by
              sending an email, of the website or application.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Unreported Adjustments</u>.
              </b>{" "}
              Minor adjustments to this Privacy Policy may occur without
              significantly affect the ways in which We treat information
              collected, so that these will not need to be informed.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- COMMUNICATION -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Sending Communications</u>.
              </b>{" "}
              <b>You</b>, by registering, agree that We will send to the your{" "}
              <i>email</i> notifications, advertisements, news about services
              and important information about using the Platform that needs your
              attention.
              <ol>
                <br></br>
                <li className="sub-item list-item">
                  <b>
                    <u>Option for Non-receipt</u>.
                  </b>{" "}
                  When you receive an <i>e-mail</i> on behalf of the Platform,
                  there will be a possibility of opting out of receiving them,
                  using the opt-out option or via <i>email</i> request.
                </li>
              </ol>
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>AntiSpam Policy</u>.
              </b>{" "}
              The Platform takes the necessary precautions to avoid sending
              requested from <i>emails</i>.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Confidentiality</u>.
              </b>{" "}
              Maximum confidentiality is ensured when processing the list of
              phones and <i>emails</i> during regular <b>Our</b> administration.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- PERSONAL DATA SECURITY -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>Storage</u>.
              </b>{" "}
              Your personal account data and all information on platform are
              stored and travel safely in Amazon Web Services (AWS) servers in
              the United States America, and only employees authorized by Us may
              have access to your personal information, being obligatorily
              subject to confidentiality and strict respect for your privacy
              under the terms of this Policy.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Data Security</u>.
              </b>{" "}
              All personal data collected by <b>Us</b> about <b>You</b> are
              handled safely and confidentially, including transmission using
              modern encryption (HTTPS).
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Information Security</u>.
              </b>{" "}
              All Cittua transactions are encrypted, subject to data backup,
              monitoring tools, security policies security, access controls for
              employees, with software updated security features.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Notification</u>.
              </b>{" "}
              If <b>We</b> become aware of any violation of own security or that
              of hosting companies, including invasions, data leaks or any other
              security incident of the information, we will notify national
              authorities, <b>You</b>, if affected, about this breach and we
              will provide maximum detail regarding the nature, extent of the
              violation and the compromised data, within a reasonable period of
              time, in accordance with the provided for in articles 48, § 1, of
              the LGPD.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Storage Period</u>.
              </b>{" "}
              <b>We</b> will store your data for the entire period in which your
              registration is active.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Data Protection Officer</u>.
              </b>{" "}
              It is decided that Daniel Morim will be in charge of Data
              Protection Data from Cittua/Wiiglo and can be contacted through{" "}
              <i>e-mail</i> <u>daniel@wiiglo.com</u>, or Rua Fonseca Teles, 121,
              São Cristóvão, Rio de Janeiro/RJ, CEP 20940-200.
            </li>
          </ol>
        </li>
        <br></br>
        <h4>- GENERAL PROVISIONS -</h4>
        <li className="invisible-item list-item">
          <ol>
            <li className="list-item">
              <b>
                <u>User Responsibility</u>.
              </b>{" "}
              <b>You</b> will be responsible for <b>Us</b> indemnifying all the
              costs and damages that it may incur as a result of the violation
              of this Privacy Policy caused by you.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Cooperation with Authorities</u>.
              </b>{" "}
              <b>We</b> fully cooperate with any authorities or courts that may
              request the disclosure of the identity or location of anyone who
              has entered any material on the Platform that violates the Clauses
              set out in this Policy.
            </li>
            <br></br>
            <li className="list-item">
              <b>
                <u>Limitation of Liability</u>.
              </b>{" "}
              This Privacy Policy only addresses the use and disclosure of
              information collected by Our Platform. If you disclose your
              information to third party websites, different rules may be
              applied to the use of your information.
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
}

function TextSelector({ type }) {
  const language = navigator.language.toLowerCase();

  if (language === "pt-br") {
    if (type === "terms") {
      return <TermsTextPt />;
    } else if (type === "privacy") {
      return <PrivacyTextPt />;
    }
  } else {
    if (type === "terms") {
      return <TermsTextEn />;
    } else if (type === "privacy") {
      return <PrivacyTextEn />;
    }
  }

  return null;
}

function TextSelectorPt({ type }) {
  if (type === "terms") {
    return <TermsTextPt />;
  } else if (type === "privacy") {
    return <PrivacyTextPt />;
  }

  return null;
}

function TextSelectorEn({ type }) {
  if (type === "terms") {
    return <TermsTextEn />;
  } else if (type === "privacy") {
    return <PrivacyTextEn />;
  }

  return null;
}

export { TextSelector, TextSelectorPt, TextSelectorEn };
