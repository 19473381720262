import { useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { event } from "../utils/googleAnalytics";
import IconSelector from "./IconSelector";
import FormCustom from "./tables/headerForm/FormCustom";

function TableHeader({ params }) {
  const { t } = useTranslation();
  const {
    tableType,
    searchUpdateFlag,
    setSearchText,
    HeaderRoot,
    Initial,
    submit,
  } = params;
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    //google analytics info//
    event(`open_${tableType}_search`, "search_table", `${tableType}_search`);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChange = (value, index) => {
    searchUpdateFlag();
    let newState = [...params.searchText];
    newState[index] = value;
    setSearchText(newState);
  };
  return (
    <Accordion
      expanded={open}
      classes={{ root: "table-header header-summary" }}
    >
      <FormCustom initialValues={Initial}>
        {(props) => (
          <HeaderRoot
            params={{
              ...params,
              openSearch: onOpen,
              formikProps: props,
              initialValues: Initial,
              onSubmit: submit,
              Update: searchUpdateFlag,
            }}
          />
        )}
      </FormCustom>
      <AccordionDetails classes={{ root: "header-details" }}>
        {params.columns.map(
          (column, index) =>
            !column.omit && (
              <div
                key={index}
                className={
                  column.linkedOmit === null
                    ? "c-input-search"
                    : `c-input-search--avatar ${
                        !params.hasSelectableRows ? "table-search-width" : ""
                      }`
                }
              >
                {column.linkedOmit === null && (
                  <>
                    <IconSelector
                      svg="Search"
                      classname={
                        column.searchable
                          ? "icon--search"
                          : "icon--search input-search--hidden"
                      }
                    />
                    <input
                      value={params.searchText[index]}
                      onChange={(event) =>
                        handleChange(event.target.value, index)
                      }
                      className={
                        column.searchable
                          ? "input-search"
                          : "input-search input-search--hidden"
                      }
                      placeholder={t(`common.table.search.${column.id}`)}
                    />
                  </>
                )}
              </div>
            )
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default TableHeader;
