import ExportTableMenu from "../../table_header_elements/ExportTableMenu";
import HideColumnsMenu from "../../table_header_elements/HideColumnsMenu";
import NewTableItemBtn from "../../table_header_elements/NewTableItemBtn";
import SearchTableBtn from "../../table_header_elements/SearchTableBtn";

export function HeaderForecastTable({ params }) {
  
  return (
    <div className="content-header-forecast">
      <div className="content-form-Export">
        <ExportTableMenu params={params} />,
      </div>
      <div className="content-form">
        <HideColumnsMenu params={params} />
      </div>
      <div className="content-form">
        <SearchTableBtn params={params} />
      </div>
      <div className="content-form">
        <NewTableItemBtn params={params} />,
      </div>
    </div>
  );
}
