import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import i18n from "../../i18n/i18n";
import { RainIconSelector } from "./rainReportIcons";
import { formatDateHour } from "../../utils/formatDate";

const rainIntensitySlector = (typeRain) => {
  const alertType = {
    Chuva: "rainReport.rainIntensity.rain",
    "Não Chuva": "rainReport.rainIntensity.noRain",
    "Sem Dados": "rainReport.rainIntensity.noData",
  };

  return alertType[typeRain];
};

export const RainReportConfig = {
  columns: [
    {
      id: "rain_icon",
      cell: (row) => (
        <Avatar className="icon--table-avatar">
          <RainIconSelector rainType={row["Ocorrência"]} />
        </Avatar>
      ),
      sortable: false,
      searchable: false,
      width: "6.1rem",
      linkedOmit: "teste",
    },
    {
      id: "Localidade",
      name: i18n.t("rainReport.tableColumns.locale"),
      cell: (row) => (
        <p className="locale" title={row["Localidade"]}>
          {row["Localidade"]}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "180px",
    },
    {
      id: "Data/hora inicial",
      name: i18n.t("rainReport.tableColumns.date_start"),
      cell: (row) => (
        <p className="date_start_rr">
          {formatDateHour(
            dayjs(row["Data/hora inicial"], "DD/MM/YYYY - HH:mm")
          )}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "220px",
    },
    {
      id: "Data/hora final",
      name: i18n.t("rainReport.tableColumns.date_end"),
      cell: (row) => (
        <p className="date_end_rr">
          {formatDateHour(dayjs(row["Data/hora final"], "DD/MM/YYYY - HH:mm"))}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
      width: "220px",
    },
    {
      id: "Ocorrência",
      name: i18n.t("rainReport.tableColumns.occurrence"),
      selector: (row) => (
        <p className="occurrence" title={row["Ocorrência"]}>
          {i18n.t(rainIntensitySlector(row["Ocorrência"]))}
        </p>
      ),
      sortable: true,
      searchable: true,
      linkedOmit: null,
    },
  ],
  hiddenColumns: [],
  initialSort: "Data/hora inicial",
  initialOrder: "desc",
  permission: "rainfall_report",
  hasSelectableRows: false,
  requestUrls: {
    get: "api/rain-report/latest",
    post: "api/rain-report",
  },
};
