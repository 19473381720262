import React from "react";
import { Formik, Form } from "formik";

function FormCustom({ children, handleSubmit, initialValues, validation }) {
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validation}
      >
        {(props) => {
          const className =
            children(props).props.params.tableType === "rainReport"
              ? "form-rain-table"
              : "form-alert-table";

          return (
            <Form onChange={props.handleChange} className={className}>
              {children(props)}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default FormCustom;
