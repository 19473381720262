import { 
	SET_USER,
	SET_RECOVERY_EMAIL,
} from '../actions/user';

const initialState = {
	data: {},
	email: '',
}

export default function user(state=initialState, action) {
	switch(action.type) {
		case SET_USER:
			return {
				...state,
				data: action.data,
			}
		case SET_RECOVERY_EMAIL:
			return {
				...state,
				email: action.email
			}
		default:
			return state
	}
}