import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationPT from "../../locales/pt/translation";
import translationEN from "../../locales/en/translation.json";
import { enUS as enUsMui, ptBR as ptBrMui } from "@mui/x-date-pickers/locales";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import pt from "date-fns/locale/pt";

export const browserLanguage = () => {
  return navigator.language.toLowerCase();
};

export const selectMuiLocale = () => {
  const language = browserLanguage();
  let locale = enUsMui;

  if (language === "pt-br" || "pt-pt") {
    locale = ptBrMui;
  }
  return locale;
};

export const selectLocale = () => {
  const language = browserLanguage();
  let locale = enUS;

  if (language === "pt-br" || "pt-pt") {
    locale = ptBR;
  }
  return locale;
};

export const selectCalendarLocale = () => {
  const language = browserLanguage();
  let locale = enUS;

  if (language === "pt-br" || "pt-pt") {
    locale = pt;
  }
  return locale;
};

const resources = {
  pt: {
    translation: translationPT,
  },
  en: {
    translation: translationEN,
  },
};

const splitBrowserLanguage = browserLanguage().split("-")[0];

i18n.use(initReactI18next).init({
  resources,
  lng: splitBrowserLanguage,
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
