import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import IconSelector from '../../components/IconSelector';
import i18n from '../../i18n/i18n';
import { optionSelector } from '../../components/form_elements/SelectInput';
import { formatDateWithoutHour } from '../../utils/formatDate';

export const usersTableConfig = {
		columns: [
			{
				id: 'photo',
				cell: (row) => <Avatar className='icon--table-avatar' src={row.photo}><IconSelector svg='Users'/></Avatar>,
				sortable: false,
				searchable: true,
				width: '6.3rem',
				linkedOmit: 'name',
			},
			{
				id: 'name',
				name: i18n.t('users.tableColumns.name'),
				selector: (row) => row.name,
				sortable: true,
				searchable: true,
				linkedOmit: null,
			},
			{
				id: 'email',
				name: i18n.t('users.tableColumns.email'),
				selector: (row) => row.email,
				sortable: true,
				searchable: true,
				linkedOmit: null,
			},
			{
				id: 'profile_name',
				name: i18n.t('users.tableColumns.profile_name'),
				selector: (row) => {
					const translatedOptions = row.profile_name.map(optionSelector).map(option => i18n.t(option));
					return translatedOptions.length > 1 ? translatedOptions.join(", ") : translatedOptions[0];
				},				
				sortable: false,
				searchable: true,
				linkedOmit: null,
			},
			{
				id: 'creation_date',
				name: i18n.t('users.tableColumns.creation_date'),
				selector: (row) => formatDateWithoutHour(dayjs(row.creation_date)),
				sortable: true,
				searchable: true,
				linkedOmit: null,
			},
			{
				id: 'status',
				name: i18n.t('users.tableColumns.status'),
				selector: (row) => row.status ? 
				<div className='c-status c-status--active'>{i18n.t('users.profiles.activated')}</div>: 
				<div className='c-status c-status--inactive'>{i18n.t('users.profiles.disabled')}</div>,
				sortable: false,
				searchable: false,
				linkedOmit: null,
			},
		],
		hiddenColumns: [],
		initialSort: 'name',
		initialOrder: 'asc',
		permission: 'user',
		hasSelectableRows: true,
		requestUrls: {
			get: 'api/user',
			delete: '',
			updateStatus: 'api/cittua/users/activate',
		}
}
