import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import SelectLocales from "../../StyledComponents/LocalesDropdown/SelectLocales";
import SelectExport from "../../StyledComponents/SelectExport/SelectExport";
import ButtonLabel from "../../StyledComponents/ButtonLabel/ButtonLabel";
import dayjs from "dayjs";

import { getRainReportLocationsOptions } from "../../../utils/dataAPI";
import { RainOccurrence } from "../../StyledComponents/RainOccurrence/RainOccurrence";
import { DateRangeCalendar } from "../../StyledComponents/DateRangeCalendar/DateRangeCalendar";

export function HeaderRainReportTable({ params }) {
  const { Update, exportOptions, searchOptions, setSearchOptions, isLoading } =
    params;
  const dispatch = useDispatch();

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedOccurrences, setSelectedOccurrences] = useState([]);

  const [calendarDate, setCalendarDate] = useState([
    {
      startDate: null,
      endDate: new Date(null),
      key: "selection",
    },
  ]);

  const today = new Date();

  const sixMonthsBefore = new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    today.getDate()
  );

  const handleSelectedOptionsChange = (option) => {
    if (selectedOptions.includes(option.local_id)) {
      setSelectedOptions(
        selectedOptions.filter((id) => id !== option.local_id)
      );
    } else {
      setSelectedOptions([...selectedOptions, option.local_id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === locationsOptions.length) {
      setSelectedOptions([]);
    } else {
      const allOptionIds = locationsOptions.map((option) => option.local_id);
      setSelectedOptions(allOptionIds);
    }
  };

  useEffect(() => {
    if (selectedOptions || selectedOccurrences || (dateStart && dateEnd)) {
      handleSubmit();
    }
  }, [selectedOptions, selectedOccurrences, dateStart, dateEnd]);

  useEffect(() => {
    const getSelectedLocationNames = () => {
      if (locationsOptions && searchOptions.localIds) {
        const selectedLocations = locationsOptions.filter((option) =>
          searchOptions.localIds.includes(option.local_id)
        );

        const selectedLocationNames = selectedLocations.map(
          (option) => option.local_name
        );

        return selectedLocationNames;
      }
      return [];
    };

    const names = getSelectedLocationNames();
    setSelectedNames(names);
  }, [searchOptions, locationsOptions]);

  const getLocationIdByName = (name) => {
    const location = locationsOptions.find((loc) => loc.local_name === name);
    return location ? location.local_id : null;
  };

  useEffect(() => {
    getRainReportLocationsOptions(dispatch).then((response) => {
      if (response && response.data) {
        setLocationsOptions(response.data);
      }
    });
  }, []);

  const handleSubmit = () => {
    setSearchOptions({
      dateStart: dateStart,
      dateEnd: dateEnd,
      localIds: selectedOptions,
      occurrences: selectedOccurrences,
    });

    Update();
  };

  useEffect(() => {
    calendarDate[0].startDate
      ? setDateStart(
          dayjs(calendarDate[0].startDate).format("YYYY-MM-DD HH:mm:ss")
        )
      : setDateStart(null);

    calendarDate[0].endDate
      ? setDateEnd(dayjs(calendarDate[0].endDate).format("YYYY-MM-DD HH:mm:ss"))
      : setDateEnd(null);
  }, [calendarDate]);

  const handleRemoveLocale = (name) => {
    const locationId = getLocationIdByName(name);
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((option) => option !== locationId)
    );
  };

  const handleRemoveDate = () => {
    setDateEnd(null);
    setDateStart(null);
    setCalendarDate([
      {
        startDate: null,
        endDate: new Date(null),
        key: "selection",
      },
    ]);
  };

  const handleSelectedOccurrences = (id) => {
    setSelectedOccurrences((prevOptions) => {
      if (prevOptions.includes(id)) {
        return prevOptions.filter((option) => option !== id);
      } else {
        return [...prevOptions, id];
      }
    });
  };

  return (
    <div>
      <div className="content-form-rain-report">
        <div className="content-form-rain-report">
          <SelectLocales
            locationsOptions={locationsOptions}
            onSelectedOptionsChange={handleSelectedOptionsChange}
            onSelectAll={handleSelectAll}
            disable={isLoading}
          />
          <DateRangeCalendar
            minDate={sixMonthsBefore}
            maxDate={today}
            calendarDate={calendarDate}
            setCalendarDate={setCalendarDate}
            disable={isLoading}
          ></DateRangeCalendar>
          <RainOccurrence
            handleSelectedOccurrences={handleSelectedOccurrences}
            disable={isLoading}
          />
        </div>
        <div>
          <SelectExport exportOptions={exportOptions} />
        </div>
      </div>
      <div style={{ padding: "2.2rem 0rem 2.2rem 0rem" }}>
        <ButtonLabel
          selectedNames={selectedNames}
          handleClose={handleRemoveLocale}
          disable={isLoading}
        ></ButtonLabel>
        <ButtonLabel
          selectedNames={
            searchOptions.dateStart && searchOptions.dateEnd
              ? [
                  `${dayjs(searchOptions.dateStart).format(
                    "DD/MM/YYYY"
                  )} à ${dayjs(searchOptions.dateEnd).format("DD/MM/YYYY")}`,
                ]
              : null
          }
          handleClose={handleRemoveDate}
          disable={isLoading}
        ></ButtonLabel>
      </div>
    </div>
  );
}
