import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {  useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CustomForm from '../../CustomForm'
import PermissionsControl from '../../PermissionsControl'
import LocalData from './LocalData'
import ForecastData from './ForecastData'
import Forecast24hData from './Forecast24hData'

function ForecastsForm() {
	const { t } = useTranslation()
	const { itemId } = useParams()
	const [activeTab, setActiveTab ] = useState(0)
	const categories = ['LocalData', 'ForecastData', 'Forecast24hData']
	//TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
	//const isInsideBoundary = useSelector(state => state.formValidation.isInsideBoundary)
	const hasIntersection = useSelector(state => state.formValidation.hasIntersection)

	const forecastTitle = [
		t('forecasts.fields.multiForm.afternoon'), 
		t('forecasts.fields.multiForm.night'), 
		t('forecasts.fields.multiForm.dawn'), 
		t('forecasts.fields.multiForm.tomorrow_morning')]

	function getInitialValues(item) {
		let initialValues = {
			location: item?.location || '',
			alias: item?.alias || '',
			sky_condition: item?.sky_condition || '',
			temperature: {
				min_temperature: item.temperature?.min_temperature || 27,
				max_temperature: item.temperature?.max_temperature || 32,
			},
			wind_condition: item?.wind_condition || '',
			humidity: {
				min_humidity: item.humidity?.min_humidity || 70,
				max_humidity: item.humidity?.max_humidity || 90,
			},
			description: item?.description || '',
			icon: item?.icon || '',
			sky_condition_1: item.forecast_24h?.[0].sky_condition_1 || '',
			temperature_1: {
				min_temperature_1: item.forecast_24h?.[0].temperature_1.min_temperature_1 || 27,
				max_temperature_1: item.forecast_24h?.[0].temperature_1.max_temperature_1 || 32,
			},
			wind_condition_1: item.forecast_24h?.[0].wind_condition_1 || '',
			humidity_1: {
				min_humidity_1: item.forecast_24h?.[0].humidity_1.min_humidity_1 || 70,
				max_humidity_1: item.forecast_24h?.[0].humidity_1.max_humidity_1 || 90,
			},
			description_1: item.forecast_24h?.[0].description_1 || '',
			icon_1: item?.icon || '',
			sky_condition_2: item.forecast_24h?.[1].sky_condition_2 || '',
			temperature_2: {
				min_temperature_2: item.forecast_24h?.[1].temperature_2.min_temperature_2 || 27,
				max_temperature_2: item.forecast_24h?.[1].temperature_2.max_temperature_2 || 32,
			},
			wind_condition_2: item.forecast_24h?.[1].wind_condition_2 || '',
			humidity_2: {
				min_humidity_2: item.forecast_24h?.[1].humidity_2.min_humidity_2 || 70,
				max_humidity_2: item.forecast_24h?.[1].humidity_2.max_humidity_2 || 90,
			},
			description_2: item.forecast_24h?.[1].description_2 || '',
			icon_2: item?.icon || '',
			sky_condition_3: item.forecast_24h?.[2].sky_condition_3 || '',
			temperature_3: {
				min_temperature_3: item.forecast_24h?.[2].temperature_3.min_temperature_3 || 27,
				max_temperature_3: item.forecast_24h?.[2].temperature_3.max_temperature_3 || 32,
			},
			wind_condition_3: item.forecast_24h?.[2].wind_condition_3 || '',
			humidity_3: {
				min_humidity_3: item.forecast_24h?.[2].humidity_3.min_humidity_3 || 70,
				max_humidity_3: item.forecast_24h?.[2].humidity_3.max_humidity_3 || 90,
			},
			description_3: item.forecast_24h?.[2].description_3 || '',
			icon_3: item?.icon || '',
			sky_condition_4: item.forecast_24h?.[3].sky_condition_4 || '',
			temperature_4: {
				min_temperature_4: item.forecast_24h?.[3].temperature_4.min_temperature_4 || 27,
				max_temperature_4: item.forecast_24h?.[3].temperature_4.max_temperature_4 || 32,
			},
			wind_condition_4: item.forecast_24h?.[3].wind_condition_4 || '',
			humidity_4: {
				min_humidity_4: item.forecast_24h?.[3].humidity_4.min_humidity_4 || 70,
				max_humidity_4: item.forecast_24h?.[3].humidity_4.max_humidity_4 || 90,
			},
			description_4: item.forecast_24h?.[3].description_4 || '',
			icon_4: item?.icon || '',
		}

		return initialValues
	}

	function formValidation(values) {
		let errors = {}

		if (categories[activeTab] === 'LocalData') {
			if (!values.location) {
				errors.location = t('common.inputErrors.fieldRequired')
			} 
			//TODO: Validação de interseção com os boundaries removida temporariamente (tarefa CT-247)
			/*else if (values.location && !isInsideBoundary) {
				errors.location = t('common.inputErrors.outOfBounds')
			}*/ else if (values.location && hasIntersection) {
				errors.location = t('common.inputErrors.hasIntersection')
			}

			if (!values.alias) {
				errors.alias = t('common.inputErrors.fieldRequired')
			}

			return errors
		}

		if (categories[activeTab] === 'ForecastData') {
			if (!values.sky_condition) {
				errors.sky_condition = t('common.inputErrors.fieldRequired')
			}

			if (!values.temperature.min_temperature || !values.temperature.max_temperature) {
				errors.temperature = t('common.inputErrors.fieldRequired')
			}

			if (!values.wind_condition) {
				errors.wind_condition = t('common.inputErrors.fieldRequired')
			}

			if (!values.humidity.min_humidity || !values.humidity.max_humidity) {
				errors.humidity = t('common.inputErrors.fieldRequired')
			}

			if (!values.description) {
				errors.description = t('common.inputErrors.fieldRequired')
			}

			return errors
		}

		forecastTitle.forEach((title, index) => {
			if (!values[`sky_condition_${index+1}`]) {
				errors[`sky_condition_${index+1}`] = t('common.inputErrors.fieldRequired')
			}
	
			if (!values[`temperature_${index+1}`][`min_temperature_${index+1}`] || !values[`temperature_${index+1}`][`max_temperature_${index+1}`]) {
				errors.temperature_1 = t('common.inputErrors.fieldRequired')
			}
	
			if (!values[`wind_condition_${index+1}`]) {
				errors[`wind_condition_${index+1}`] = t('common.inputErrors.fieldRequired')
			}
	
			if (!values[`humidity_${index+1}`][`min_humidity_${index+1}`] || !values[`humidity_${index+1}`][`max_humidity_${index+1}`]) {
				errors[`humidity_${index+1}`] = t('common.inputErrors.fieldRequired')
			}
	
			if (!values[`description_${index+1}`]) {
				errors[`description_${index+1}`] = t('common.inputErrors.fieldRequired')
			}
		})

		return errors
	}

	function formatSubmitValues(values) {
		const { 
			sky_condition_1,
			temperature_1,
			wind_condition_1,
			humidity_1,
			description_1,
			icon_1,
			sky_condition_2,
			temperature_2,
			wind_condition_2,
			humidity_2,
			description_2,
			icon_2,
			sky_condition_3,
			temperature_3,
			wind_condition_3,
			humidity_3,
			description_3,
			icon_3,
			sky_condition_4,
			temperature_4,
			wind_condition_4,
			humidity_4,
			description_4,
			icon_4,
			...remainingValues
		} = values

		remainingValues['forecast_24h'] = [
			{
				sky_condition_1,
				temperature_1,
				wind_condition_1,
				humidity_1,
				description_1,
				icon_1,
				titulo_1: "Tarde",
			},
			{
				sky_condition_2,
				temperature_2,
				wind_condition_2,
				humidity_2,
				description_2,
				icon_2,
				titulo_2: "Noite",
			},
			{
				sky_condition_3,
				temperature_3,
				wind_condition_3,
				humidity_3,
				description_3,
				icon_3,
				titulo_3: "Madrugada",
			},
			{
				sky_condition_4,
				temperature_4,
				wind_condition_4,
				humidity_4,
				description_4,
				icon_4,
				titulo_4: "Amanhã de manhã",
			}
		]

		const submitValues = {
			form: remainingValues,
			requestUrl: itemId ? `api/weather/forecast/${itemId}` : 'api/weather/forecast',
			method: itemId ? 'patch' : 'post'
		}

		return submitValues
	}

	function FormTabs({tab, props}) {
		const Tabs = {
			LocalData,
			ForecastData,
			Forecast24hData,
		}

		let Tab = Tabs[tab]

		return Tab !== undefined ? <Tab props={props}/> : null
	}

	useEffect(() => {
		document.title = itemId ? t('forecasts.pageTitle.edit') : t('forecasts.pageTitle.create')
	})

	return (
		<div className='c-main-content c-main-content--form'>
			<PermissionsControl
				role='weather'
				type='componentPage'
				action='update'
			>
				<CustomForm
					params={{
						formType: 'forecasts',
						categories,
						setActiveTab,
						getInitialValues,
						formValidation,
						formatSubmitValues,
						requestData: {
							requestUrl: 'api/weather/forecast',
							method: 'get',
						}
					}}
				>
					{(formProps) => (
						<FormTabs tab={categories[activeTab]} props={{formProps, itemId}}/>
					)}
				</CustomForm>
			</PermissionsControl>
		</div>
	)
}

export default ForecastsForm