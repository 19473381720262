export const customStylesLightTheme = {
  rows: {
    style: {
      backgroundColor: "transparent",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: ".1rem",
        borderBottomColor: "#E0E0E0",
      },
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      "&:nth-of-type(n)": {
        color: "#4120CE",
        backgroundColor: "#C7BDF5",
        borderBottomColor: "#E0E0E0",
      },
    },
  },
  pagination: {
    style: {
      justifyContent: "center",
    },
  },
};

export const customStylesDarkTheme = {
  rows: {
    style: {
      backgroundColor: "transparent",
      color: "#FFFFFF",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: ".1rem",
        borderBottomColor: "#666666",
      },
    },
    selectedHighlightStyle: {
      // use nth-of-type(n) to override other nth selectors
      "&:nth-of-type(n)": {
        color: "#4120CE",
        backgroundColor: "#C7BDF5",
        borderBottomColor: "#666666",
      },
    },
  },
  pagination: {
    style: {
      justifyContent: "center",
    },
  },
};
