import * as S from "./ButtonLabel.styled";

function ButtonLabel({ selectedNames, handleClose, disable }) {
  return (
    <>
      {selectedNames && (
        <S.StyledContainer>
          {selectedNames.map((name, index) => (
            <S.StyledButton
              key={index}
              type="button"
              onClick={() => handleClose(name)}
              disabled={disable}
            >
              {name}
              <S.XClose>X</S.XClose>
            </S.StyledButton>
          ))}
        </S.StyledContainer>
      )}
    </>
  );
}

export default ButtonLabel;
