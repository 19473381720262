import { useTranslation } from 'react-i18next';

function Footer() {
	const { t } = useTranslation();

	return (
		<div className='c-footer'>
			<p className='footer-text'>{t('footer.text')}</p>
		</div>
	)
}

export default Footer