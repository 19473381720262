import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  Pane,
} from "react-leaflet";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import "leaflet/dist/leaflet.css";

import { getMapLayers } from "../utils/dataAPI";
import { titleLayerConfig } from "../data/map-config";
import MapDrawingControls from "./MapDrawingControls";
import { ButtonSelectionMap } from "./mapControlButton/ButtonSelectionMap";

function MapComponent({ params }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [mapLayers, setMapLayers] = useState(null);
  const [previewLayer, setPreviewLayer] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [boundary, setBoundary] = useState(null);

  const {
    initialMapSettings,
    lastUpdateFlag,
    drawingControls,
    inputParams,
    onError,
    previewForecasts,
    date,
    time,
    showLegendDialog,
    showCalendarDialog,
    isPermissionActive,
  } = params;

  const layerStyles = (feature) => {
    if (feature.properties && Object.keys(feature.properties).length !== 0) {
      return {
        fillColor: feature.properties.fill,
        fillOpacity: 0.6,
        color: feature.properties.stroke,
        weight: feature.properties["stroke-width"],
        opacity: 0.1,
        snapIgnore: true,
      };
    }

    return {
      fill: 0,
      color: "#000000",
      weight: 3,
      snapIgnore: true,
    };
  };

  const previewLayerStyles = (feature) => {
    return {
      fillColor: "gray",
      fillOpacity: 0.4,
      color: "gray",
      weight: 2,
      pmIgnore: true,
      snapIgnore: true,
    };
  };

  useEffect(() => {
    if (!map) return;

    let currentMapBounds = map.target.getBounds();

    let boundingBox = [
      currentMapBounds._northEast.lat,
      currentMapBounds._northEast.lng,
      currentMapBounds._southWest.lat,
      currentMapBounds._southWest.lng,
    ];

    const fetchData = async () => {
      try {
        setLoading(true);
        const layerParams = {
          dispatch,
          datetime: `${dayjs(date).format("YYYY-MM-DD")} ${dayjs(time).format(
            "HH:00:00"
          )}`,
          boundingBox,
          preview_forecasts: previewForecasts,
        };
        const layerData = await getMapLayers(layerParams);
        if (layerData && layerData.success) {
          const { last_update, boundaries, preview_forecasts, ...layers } =
            layerData.data;

          let arrangedLayers = Object.values(layers);

          arrangedLayers.push(boundaries);
          setBoundary(boundaries);
          if (
            preview_forecasts &&
            Object.keys(preview_forecasts).length !== 0
          ) {
            setPreviewLayer(preview_forecasts);
          }

          setLastUpdate(last_update);
          setMapLayers(arrangedLayers);
        } else {
          onError(true);
        }
      } catch (error) {
        onError(true);
        setLoading(false);
      }

      setLoading(false);
    };

    fetchData();
  }, [map, date, time]);

  return (
    <MapContainer
      bounds={initialMapSettings.initial_bounding_box}
      scrollWheelZoom={true}
      zoomControl={false}
      whenReady={setMap}
    >
      <TileLayer
        attribution={titleLayerConfig.attribution}
        url={titleLayerConfig.url}
      />
      <Pane name="map-mapLayers" style={{ zIndex: 200 }}>
        {mapLayers &&
          mapLayers.length !== 0 &&
          mapLayers.map((layer, index) => (
            <GeoJSON
              key={Math.random() * (index + 1)}
              data={layer}
              style={layerStyles}
            />
          ))}
        {previewForecasts && previewLayer && (
          <GeoJSON
            key="preview-layer"
            data={previewLayer}
            style={previewLayerStyles}
          />
        )}
      </Pane>

      <ZoomControl position="bottomright" />
      {isPermissionActive && (
        <ButtonSelectionMap
          icon={"Maplegend"}
          className={"map-legend-button"}
          isOpenModal={showLegendDialog}
          buttonClassName={"map-legend-button-container"}
        />
      )}
      {isPermissionActive && (
        <ButtonSelectionMap
          icon={"Mapcalendar"}
          className={"map-calendar-button"}
          isOpenModal={showCalendarDialog}
          buttonClassName={"map-calendar-button-container"}
        />
      )}

      {lastUpdateFlag && (
        <div className="c-last-update">
          <p className="map-last-update">
            {lastUpdate !== undefined
              ? t(`nwpForecast.last_update`, {
                  date: dayjs(lastUpdate).format("DD/MM/YYYY"),
                  time: dayjs(lastUpdate).format("HH:mm"),
                })
              : t("nwpForecast.noData")}
          </p>
        </div>
      )}
      {loading && (
        <div className="c-top-msg">
          <div className="c-loading-map-info">
            <p className="loading-map-text">{t("nwpForecast.loading_map")}</p>
            <CircularProgress
              className="loading-map-icon"
              size={20}
              thickness={5}
            />
          </div>
        </div>
      )}
      {drawingControls && (
        <MapDrawingControls
          params={{ ...inputParams, boundary, previewLayer }}
        />
      )}
    </MapContainer>
  );
}

export default MapComponent;
