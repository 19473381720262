import { useEffect } from 'react'
import { useLeafletContext } from "@react-leaflet/core"
import { useDispatch } from 'react-redux'
import * as L from "leaflet"
import * as turf from '@turf/turf'

import "@geoman-io/leaflet-geoman-free"
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css"
import { setAreaValidation , setIntersectionValidation } from '../redux/actions/formValidation'

function MapDrawingControls({params}) {
	const context = useLeafletContext()
	const { handleMapChange, mapValue, boundary, previewLayer } = params
	const leafletContainer = context.layerContainer || context.map
	const dispatch = useDispatch()

	useEffect(() => {
		leafletContainer.pm.addControls({
			position: 'topleft',
			drawMarker: false,
			drawCircleMarker: false,
			drawRectangle: false,
			drawPolyline: false,
			drawCircle: false,
			drawText: false,
			dragMode: false,
			editMode: false,
			cutPolygon: false,
			rotateMode: false,
		})

		leafletContainer.pm.setGlobalOptions({ pmIgnore: false });

		leafletContainer.on("pm:create", (e) => {
			
			if (e.layer && e.layer.pm) {
				handleMapChange(leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features)
			}
		})

		leafletContainer.on("pm:remove", (e) => {

		const polygonRemoved = e.layer; 
			const coordinatesOfTheRemovedPolygons = polygonRemoved.feature.geometry.coordinates
			handleMapChange(leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features,coordinatesOfTheRemovedPolygons)
    	});

	  	return () => {
      		leafletContainer.pm.removeControls();
      		leafletContainer.pm.setGlobalOptions({ pmIgnore: true });
    	};
	})

	useEffect(() => {
		//TODO: validate if the area is inside the boundary when boundary is a multipolygon, because turf.booleanContains doesn't work with multipolygons
		if (boundary && leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features.length !== 0 && boundary.type === "Polygon") {
			const bufferedBoundary = turf.buffer(boundary, 0.001, {steps: 1})
			let isInsideBoundary = Array.from(
				leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features, 
				(element) => turf.booleanContains(bufferedBoundary, turf.polygon(element.geometry.coordinates))

			).every(value => value)
			dispatch(setAreaValidation(isInsideBoundary))
		} else {
			dispatch(setAreaValidation(true))
		}

		if (previewLayer && leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features.length !== 0) {
			let noIntersection =true
			
			for (let layer of previewLayer) {
				noIntersection = Array.from(
					leafletContainer.pm.getGeomanDrawLayers(true).toGeoJSON().features,
					(element) => turf.intersect(layer, element)
				).every(value => { return value === null } )

				if (!noIntersection) break
			}
			
			dispatch(setIntersectionValidation(!noIntersection))
		} else {
			dispatch(setIntersectionValidation(false))
		}
	}, [mapValue])

	useEffect(() => {
		if (mapValue.length !== 0) {
			L.geoJSON(mapValue).addTo(leafletContainer)
		}
	},[])

	return null
}

export default MapDrawingControls