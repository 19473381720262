import { useState, useEffect } from 'react'
import { useLocation, useNavigate, matchPath } from "react-router-dom"
import { useSelector } from 'react-redux'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'

import { sidebarConfig } from '../data/sidebar-config'
import SidebarElement from './SidebarElement'
import IconSelector from './IconSelector'
import PermissionsControl from './PermissionsControl'

function Sidebar({params}) {
	let location = useLocation()
	const navigate = useNavigate()
	const user = useSelector(state => state.user.data)
	const theme = useSelector(state => state.uiActions.theme)
	const [loading, setLoading] = useState(true)
	const [selectedIndex, setSelectedIndex] = useState(sidebarConfig.indexOrder.findIndex(element => element.itemPath === location.pathname))

	const onClickMenuItem = (index) => {
		setSelectedIndex(index);
		navigate(sidebarConfig.indexOrder[index].itemPath);
	}

	useEffect(() => {
		if (Object.keys(user).length === 0) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [user])

	//Checks the pathname on screen refresh to guarantee that the sidebar stays selected in the correct item on subpaths like /path/id, /path/create, etc//
	useEffect(() => {
		sidebarConfig.indexOrder.forEach((item, index) => {
			const match = matchPath({	path: item.itemPath, end: false}, location.pathname)

			if (match !== null && (location.pathname !== '/')) {
				setSelectedIndex(index)
			}

		})
	}, [location])

	return (
		<div className={`c-sidebar theme--${theme}`}>
			<Button 
				onClick={params.sidebarControl} 
				classes={{root: params.sidebarStatus ? 'btn-sidebar btn-sidebar--open' : 'btn-sidebar btn-sidebar--closed'}}
			>	
				<IconSelector svg={params.sidebarStatus ? 'ArrowLeft2' : 'ArrowRight'} className={'icon--btn-sidebar'}/>			
			</Button>
			<Drawer
				variant='permanent'
				classes={{
					paper: params.sidebarStatus ? 'sidebar sidebar--open' : 'sidebar sidebar--closed'
				}}
			>
				<div className='c-sidebar__logo'>
					<img alt='Logo do Cittua' className='sidebar__logo' src={params.sidebarStatus ? sidebarConfig.logo.open : sidebarConfig.logo.closed}></img>
				</div>
				<Divider classes={{root: 'sidebar__divider'}}/>
				<div className='c-sidebar__main-menu'>
					{
						sidebarConfig.mainMenu.items.map((item) => (
							<div key={item.id} className='c-sidebar-item'>
							{
								loading ? 
								<div className='skeleton-sidebar-item'>
									<Skeleton variant='circle' animation='wave' classes={{root: 'skeleton-sidebar-item--icon'}}/>
									<Skeleton variant='text' animation='wave' classes={{root: 'skeleton-sidebar-item--txt'}}/>
								</div> 
								:
								<PermissionsControl
									role={item.permission}
									type='componentItem'
									action='read'
								>
									<SidebarElement 
										props={{
											itemConfig: item,
											onClickFunction: onClickMenuItem,
											selectedIndex: selectedIndex,
											sidebarStatus: params.sidebarStatus,
											sidebarControl: params.sidebarControl,
										}}
									/>									
								</PermissionsControl>
							}
							</div>
						))
					}
				</div>
				<Divider classes={{root: 'sidebar__divider sidebar__divider-margin'}}/>
				<div className='c-sidebar__sub-menu'>
					{
						sidebarConfig.subMenu.items.map((item) => (
							<div key={item.id} className='c-sidebar-item'>
							{
								loading ?
								<div className='skeleton-sidebar-item'>
									<Skeleton variant='circle' animation='wave' classes={{root: 'skeleton-sidebar-item--icon'}}/>
									<Skeleton variant='text' animation='wave' classes={{root: 'skeleton-sidebar-item--txt'}}/>
								</div> 
								:
								<PermissionsControl
									role={item.permission}
									type='componentItem'
									action='read'
								>
									<SidebarElement 
										props={{
											itemConfig: item,
											onClickFunction: onClickMenuItem,
											selectedIndex: selectedIndex,
											sidebarStatus: params.sidebarStatus,
											sidebarControl: params.sidebarControl,
										}}
									/>
								</PermissionsControl>
							}
							</div>
						))
					}
				</div>
			</Drawer>
		</div>
	)
}

export default Sidebar