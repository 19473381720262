import { headerConfig } from '../data/header-config';
import HeaderElement from './HeaderElement'

function Header({params}) {
	return(
		<div className={params.sidebarStatus ? 'c-header c-header--open' : 'c-header c-header--closed'}>
			{
				headerConfig.components.map(component => (
					<HeaderElement key={component.type} params={{
						...component,
						...params,
					}}/>
				))
			}
		</div>
	)
}

export default Header