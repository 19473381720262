import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #4120ce;
  border-radius: 4px;
  color: #4120ce;
  padding: 0px 8px 0px 8px;
`;

export const OccurrenceText = styled.text`
  color: #4120ce;
  font-size: 1.4rem;
  letter-spacing: 8;
  line-height: 160%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
`;

export const RainText = styled.text`
  color: #292929;
  font-size: 1.4rem;
  letter-spacing: 8;
  line-height: 160%;
  font-family: "Inter", sans-serif;
  font-weight: Regular;
  margin-left: 1rem;
`;
