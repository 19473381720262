import Button from "@mui/material/Button";
import IconSelector from "../IconSelector";
import { useTranslation } from "react-i18next";

function SearchTableBtn({ params, onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick ? onClick : params.openSearch}
      classes={{ root: "btn-secondary btn-secondary--medium btn-table-search search-table-btn" }}
    >
      {t('headerTableBtn.search')}
      <IconSelector svg="Search" classname="icon--Small icon--Search-btn btn-icon-search" />
    </Button>
  );
}

export default SearchTableBtn;
