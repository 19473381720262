import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import { requestRecovery } from "../utils/authAPI";
import { handleLogin, setRecoveryEmail } from "../redux/actions/user";
import logo from "../../assets/img/logo-cittua.png";
import IconSelector from "./IconSelector";
import Modal from "./Modal";
import BtnLoadingText from "./BtnLoadingText";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [openRecovery, setOpenRecovery] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [textMode, setTextMode] = useState("");
  const [openTextModal, setOpenTextModal] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const loginInitialValues = {
    username: "",
    password: "",
    termsAndPrivacy: false,
    captcha: null,
  };

  const submitLogin = (values, actions) => {
    dispatch(
      handleLogin(values.username, values.password, values.captcha)
    ).then((response) => {
      if (response.result === "loginSuccess") {
        response.tenant.length === 0
          ? actions.setFieldError(
              "username",
              "Este usuário não está vinculado a nenhum contrato. Entre em contato com o suporte"
            )
          : response.tenant.length > 1
          ? navigate("/select-tenant")
          : navigate("/");
      } else {
        actions.setFieldError("username", t(`common.inputErrors.${response}`));
        actions.setFieldError("password", t(`common.inputErrors.${response}`));
      }
      actions.setSubmitting(false);
    });
  };

  const loginValidation = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = t("common.inputErrors.fieldRequired");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
    ) {
      errors.username = t("common.inputErrors.invalidUsername");
    }

    if (!values.password) {
      errors.password = t("common.inputErrors.fieldRequired");
    }

    if (!values.termsAndPrivacy) {
      errors.termsAndPrivacy = t("common.inputErrors.acceptTerms");
    }

    if (values.captcha === null) {
      errors.captcha = t("common.inputErrors.verifyCaptcha");
    }

    return errors;
  };

  const onChangeVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const onOpenRecovery = () => {
    setOpenRecovery(true);
  };

  const onCloseRecovery = () => {
    if (emailSent) {
      navigate("/recovery");
    } else {
      setOpenRecovery(false);
      setLoginError(false);
    }
  };

  const onSendEmail = (values, actions) => {
    setLoginError(false);
    requestRecovery(values.email).then((data) => {
      if (data.success) {
        dispatch(setRecoveryEmail(values.email));
        setEmailSent(true);
      } else {
        setLoginError(true);
        setLoginErrorMessage(data.message);
      }
      actions.setSubmitting(false);
    });
  };

  const onResendEmail = () => {
    setEmailSent(false);
    dispatch(setRecoveryEmail(""));
  };

  const onOpenTextModal = (mode) => {
    setTextMode(mode);
    setOpenTextModal(true);
  };

  const onCloseTextModal = () => {
    setOpenTextModal(false);
  };

  useEffect(() => {
    document.title = t("login.pageTitle");
  });

  return (
    <div className="c-login-page theme--light">
      <div className="c-right">
        <div className="c-login-content">
          <img className="logo-img" alt="Logo do Cittua" src={logo} />
          <h1 className="login-form__title">{t("login.formTitle")}</h1>
          {/*TODO: Componentizar esse formulário*/}
          <Formik
            initialValues={loginInitialValues}
            validate={(values) => loginValidation(values)}
            onSubmit={(values, actions) => submitLogin(values, actions)}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="c-input">
                  <label className="label" htmlFor="username">
                    {t("login.fields.email")}
                  </label>
                  <input
                    id="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.username && touched.username
                        ? "input input--error"
                        : "input"
                    }
                    placeholder={t("login.placeholder.email")}
                    disabled={isSubmitting}
                  />
                  <div
                    className={
                      errors.username && touched.username
                        ? "c-error-text"
                        : "c-error-text c-error-text--hidden"
                    }
                  >
                    <IconSelector
                      svg={"ErrorWarningLine"}
                      classname={"icon--warning"}
                    />
                    <span className="error-text">{errors.username}</span>
                  </div>
                </div>
                <div className="c-input">
                  <label className="label" htmlFor="password">
                    {t("login.fields.password")}
                  </label>
                  <input
                    id="password"
                    type={passwordVisibility ? "text" : "password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? "input input--password input--error"
                        : "input input--password"
                    }
                    placeholder={t("login.placeholder.password")}
                    disabled={isSubmitting}
                  />
                  <button
                    className="btn-icon"
                    type="button"
                    onClick={onChangeVisibility}
                  >
                    <IconSelector
                      svg={passwordVisibility ? "EyeLine" : "EyeCloseLine"}
                      classname={"icon--password"}
                    />
                  </button>
                  <div
                    className={
                      errors.password && touched.password
                        ? "c-error-text"
                        : "c-error-text c-error-text--hidden"
                    }
                  >
                    <IconSelector
                      svg={"ErrorWarningLine"}
                      classname={"icon--warning"}
                    />
                    <span className="error-text">{errors.password}</span>
                  </div>
                </div>
                <Button classes={{ root: "btn-link" }} onClick={onOpenRecovery}>
                  {t("login.btn.forgotPassword")}
                </Button>
                <div className="c-checkbox">
                  <Checkbox
                    name="termsAndPrivacy"
                    checked={values.termsAndPrivacy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon={
                      <IconSelector
                        svg={"Checkbox"}
                        classname={"icon--checkbox"}
                      />
                    }
                    checkedIcon={
                      <IconSelector
                        svg={"CheckedCheckbox"}
                        classname={"icon--checkbox"}
                      />
                    }
                    classes={{
                      root: "login-checkbox",
                    }}
                    disabled={isSubmitting}
                  />
                  <label htmlFor="termsAndPrivacy" className="checkbox-label">
                    {t("login.termsAndPrivacy.part1")}&nbsp;
                    <button
                      type="button"
                      className="btn-link "
                      onClick={() => onOpenTextModal("terms")}
                    >
                      {t("login.termsAndPrivacy.terms")}
                    </button>
                    &nbsp;{t("login.termsAndPrivacy.part2")}&nbsp;
                    <button
                      type="button"
                      className="btn-link "
                      onClick={() => onOpenTextModal("privacy")}
                    >
                      {t("login.termsAndPrivacy.privacy")}
                    </button>
                    &nbsp;{t("login.termsAndPrivacy.part3")}
                  </label>
                </div>
                <div
                  className={
                    errors.termsAndPrivacy && touched.termsAndPrivacy
                      ? "c-error-text"
                      : "c-error-text c-error-text--hidden"
                  }
                >
                  <IconSelector
                    svg={"ErrorWarningLine"}
                    classname={"icon--warning"}
                  />
                  <span className="error-text">{errors.termsAndPrivacy}</span>
                </div>
                <div className="c-recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => setFieldValue("captcha", value)}
                  />
                  <div
                    className={
                      errors.captcha && touched.captcha
                        ? "c-error-text"
                        : "c-error-text c-error-text--hidden"
                    }
                  >
                    <IconSelector
                      svg={"ErrorWarningLine"}
                      classname={"icon--warning"}
                    />
                    <span className="error-text">{errors.captcha}</span>
                  </div>
                </div>
                <Button
                  classes={{
                    root: "btn-primary btn-primary--large-full-width",
                  }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <BtnLoadingText /> : t("login.btn.login")}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Modal
        type="recovery"
        params={{
          openModal: openRecovery,
          closeModal: onCloseRecovery,
          changeContentFlag: emailSent,
          changeContentFunction: onSendEmail,
          backFunction: onResendEmail,
          errorFlag: loginError,
          errorMessage: loginErrorMessage,
        }}
      />
      <Modal
        type="scrollText"
        params={{
          openModal: openTextModal,
          closeModal: onCloseTextModal,
          changeContentFlag: textMode,
        }}
      />
    </div>
  );
}

export default Login;
