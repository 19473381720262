import { styled } from "@mui/material/styles";
import MuiSwitch from "@mui/material/Switch";

export const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 52,
  height: 24,
  padding: 0,
  borderRadius: 35,
  border: "1px solid #4120CE",
  color: "#F5F5F5",
  "& .MuiSwitch-switchBase": {
    padding: 1,
    color: "#4120CE",
    "&.Mui-checked": {
      color: "#F5F5F5",
      transform: "translateX(26px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#4120CE",
        opacity: 1,
      },
      "& .MuiSwitch-thumb": {
        color: "#FFFFFF", 
        width: 20, 
        height: 20, 
        margin: 0.1, 
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "#4120CE", 
    width: 18, 
    height: 18, 
    margin: 1,
    marginLeft: 2,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#F5F5F5",
    opacity: 0.38,
    borderRadius: 35,
  },
}));
