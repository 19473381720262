import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import Button from '@mui/material/Button'
import { useCookies, Cookies } from 'react-cookie'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'

import IconSelector from './IconSelector'
import logo from '../../assets/img/logo-cittua.png'
import { checkNotification } from '../utils/notificationsFirebase'

function TenantSelection() {
  const [textInputValue, setTextInputValue] = useState('')
  const [openMenu, setOpenMenu] = useState(false)
  const [cookies] = useCookies()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const options = cookies.user?.tenant || null
  const selectInitialValues = {
    tenant: null
  }

  const handleAutoCompleteChange = (value, setFieldValue) => {
    setFieldValue('tenant', value)
  }

  const selectionValidation = (values) => {
    const errors = {}
    if (values.tenant === null) {
      errors.tenant = t('common.inputErrors.fieldRequired')
    }

    return errors
  }

  const submitTenant = (values) => {
    const tenantCookie = new Cookies()

    tenantCookie.set('tenant', values.tenant, {path: '/', domain: window.location.hostname, maxAge: 604800})

    checkNotification(values.tenant.id, cookies.user.access_token)

    navigate('/')
    
  }

  const handleOpen = (event) => {
    setOpenMenu(true)
  }

  const handleClose = (event) => {
    setOpenMenu(false)
  }

  const selectBtn = () => {
    setOpenMenu(!openMenu)
  }

  useEffect(() => {
		document.title = t('tenant-selection.pageTitle')
		if (cookies.user === undefined) {
			navigate('/login')
		}
	},[])

  return (
    <div className='c-tenant-selection theme--light'>
      <div className='selection-content'>
        <img className='logo-img' alt='Logo do Cittua' src={logo}/>
        <h1 className='tenant-selection-title'>{t('tenant-selection.title')}</h1>
        <Formik
          initialValues={selectInitialValues}
          onSubmit={(values) => submitTenant(values)}
          validate={(values) => selectionValidation(values)}
        >
        {
          ({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit
          }) => (
            <form className='login-form' onSubmit={handleSubmit}>
              <Autocomplete
                id='tenant'
                name='tenant'
                value={values.tenant}
                disablePortal
                onChange={(event, newValue) => handleAutoCompleteChange(newValue, setFieldValue)}
                inputValue={textInputValue} 
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option,value) => option.name === value.name}
                noOptionsText={<p>{t('tenant-selection.noOptions')}</p>}
                onInputChange={(Event, newInputValue) => {
                  setTextInputValue(newInputValue)
                }}
                open={openMenu}
                onOpen={(event) => handleOpen(event)}
                onClose={(event) => handleClose(event)}
                openOnFocus={true}
                options={options}
                renderInput={(params) => (
                  <div className='c-input' ref={params.InputProps.ref}>
                    <label className='label'>{t('tenant-selection.label')}</label>
                    <input
                      {...params.inputProps}
                      className={(errors.tenant && touched.tenant) ? 'input input--password input--error' : 'input input--password'}
                    />
                    <button className='btn-icon btn-icon--tenant-selector' type='button' onClick={selectBtn}>
                      <IconSelector svg={openMenu ? 'ChevronUp' : 'ChevronDown'} classname={'icon--tenant-selector'}/>
                    </button>
                    <div className={(errors.tenant && touched.tenant) ? 'c-error-text' : 'c-error-text c-error-text--hidden'}>
                      <IconSelector svg={'ErrorWarningLine'} classname={'icon--warning'}/>
                      <span className='error-text'>{errors.tenant}</span>
                    </div>
                  </div>
                )}
              />
              <Button
                classes={{root: 'btn-primary btn-primary--large-full-width btn-primary--margin-top'}}
                type='submit'
              >
                {t('tenant-selection.continueBtn')}
              </Button>
            </form>
          )
        }
        </Formik>
      </div>
    </div>
  )
}

export default TenantSelection