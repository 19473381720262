import { Cookies } from 'react-cookie'

import { requestLogin } from '../../utils/authAPI'
import { getActiveUser } from '../../utils/dataAPI'
import { setErrorStatus, setErrorType } from './errors'
import { checkNotification } from '../../utils/notificationsFirebase'


export const SET_USER = 'SET_USER'
export const SET_RECOVERY_EMAIL = 'SET_RECOVERY_EMAIL'

export function setUser(data) {
	return {
		type: SET_USER,
		data: data
	}
}

export function setRecoveryEmail(email) {
	return {
		type: SET_RECOVERY_EMAIL,
		email: email,
	}
}

export function handleLogin(username,password,captcha) {
	const cookies = new Cookies()
	return(dispatch) => {
		return requestLogin(username,password,captcha).then((response) => {
			if (response.status === 401) {
				return Promise.reject('loginUnauthorized')
			}
			if (response.status === 200) {
				return response.json()
			}
		}).then((loginResponse) => {
				if (loginResponse.success) {
					cookies.set('user', loginResponse, {path: '/', domain: window.location.hostname, maxAge: 604800})
					if (loginResponse.tenant.length === 1) {
						cookies.set('tenant', loginResponse.tenant[0], {path: '/', domain: window.location.hostname, maxAge: 604800})
						checkNotification(loginResponse.tenant[0], loginResponse.accessToken)
					}
					return Promise.resolve({result: 'loginSuccess', tenant: loginResponse.tenant})
				} else {
					return Promise.reject('loginUnauthorized')
				}
		}).catch((error) => {
			if (error === 'loginUnauthorized') {
				return error
			} else {
				return 'loginError'
			}
		})
	}
}

export function handleGetActiveUser() {
	return(dispatch) => {
		return getActiveUser(dispatch).then((response) => {
			if (response && response.success) {
				let newPermissions = {}

				response.data.permissions.forEach(permission => {
					const { service, ...permissionList } = permission
					newPermissions[service] = permissionList
				})

				response.data.permissions = newPermissions
				dispatch(setUser(response.data))
			} else {
				dispatch(setErrorStatus(true))
				if (response.type) {
					dispatch(setErrorType(response.type))
				} else {
					dispatch(setErrorType('serverError'))
				}
				Promise.reject()
			}
		}).catch(error => {
			dispatch(setErrorStatus(true))
			dispatch(setErrorType('serverError'))
		})
	}
}