import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { skyIconOptions, skyConditionOptions, windConditionOptions } from './constants'
import IconSelector from '../../IconSelector'
import RadioGroupInput from '../../form_elements/RadioGroupInput'
import RangeSliderInput from '../../form_elements/RangeSliderInput'
import TextAreaInput from '../../form_elements/TextAreaInput'
import { useTranslation } from 'react-i18next'

function Forecast24hData({props}) {
	const { t } = useTranslation()
	const forecastTitle = [
		t('forecasts.fields.multiForm.afternoon'), 
		t('forecasts.fields.multiForm.night'), 
		t('forecasts.fields.multiForm.dawn'), 
		t('forecasts.fields.multiForm.tomorrow_morning')]

	return (
		<>
			{forecastTitle.map((title, index) => (
				<div className='input-controls' key={index}>
					<Accordion 
						className='c-multi-form'
					>
						<AccordionSummary
							expandIcon={<IconSelector svg='ChevronDown' classname={'icon--multi-form-chevron'}/>}
							classes={{root: 'multi-form-summary', content: 'multi-form-content'}}
						>
							<span className='multi-form-title'>{title}</span>
						</AccordionSummary>
						<AccordionDetails>
							<div className='c-input'>
								<RadioGroupInput
									params={{
										id: `sky_condition_${index+1}`,
										editDisabled: false,
										setIconField: true,
										iconOptions: skyIconOptions,
										options: skyConditionOptions,
										iconLabel: `icon_${index+1}`,
										formProps: props.formProps,
										formType: 'forecasts',
										validations: [
											{
												type: 'required',
											}
										]
									}}
								/>
							</div>
							<div className='c-input'> 
								<RangeSliderInput 
									params={{
										id: `temperature_${index+1}`,
										editDisabled: false,
										scale: {
											min: -50,
											max: 50,
										},
										formProps: props.formProps,
										formType: 'forecasts',
										marks: [
											{
												value: -50,
												label: '-50°C',
											},
											{
												value: 50,
												label: '50°C',
											},
										 ],
										validations: [
											{
												type: 'required',
											}
										]
									}}
								/>
							</div>
							<div className='c-input'>
								<RadioGroupInput 
									params={{
										id: `wind_condition_${index+1}`,
										editDisabled: false,
										setIconField: false,
										options: windConditionOptions,
										formProps: props.formProps,
										formType: 'forecasts',
										validations: [
											{
												type: 'required',
											}
										]
									}}
								/>
							</div>
							<div className='c-input'>
								<RangeSliderInput 
									params={{
										id: `humidity_${index+1}`,
										editDisabled: false,
										scale: {
											min: 0,
											max: 100,
										},
										formProps: props.formProps,
										formType: 'forecasts',
										marks: [
											{
												value: 0,
												label: '0%',
											},
											{
												value: 100,
												label: '100%',
											},
										 ],
										validations: [
											{
												type: 'required',
											}
										]
									}}
								/>
							</div>
							<div>
								<TextAreaInput 
									params={{
										id: `description_${index+1}`,
										editDisabled: false,
										formProps: props.formProps,
										formType: 'forecasts',
										validations: [
											{
												type: 'required',
											}
										]
									}}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			))}
		</>
	)
}

export default Forecast24hData