import styled from "styled-components";

export const StyledButton = styled.button`
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  color: #292929;
  font-family: "Inter", sans-serif;
  font-weight: light;
  line-height: 120%;
  font-size: 1.2rem;
  border: none;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
  background-color: rgba(213, 205, 244, 0.5);
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 5px;
  border: none;
`;

export const XClose = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 120%;
`;
