import PageTitleComponent from './header_elements/PageTitleComponent'
import UserProfileMenu from './header_elements/UserProfileMenu'

function HeaderElement({params}) {
	const { type } = params

	switch(type) {
	case 'pageTitle':
		return <PageTitleComponent params={params}/>
	case 'userProfileMenu':
		return <UserProfileMenu params={params}/>
	default:
		return null
	}

}

export default HeaderElement