const initialState = {
  fromDate: null,
  toDate: null,
};

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FROM_DATE":
      return { ...state, fromDate: action.payload };
    case "SET_TO_DATE":
      return { ...state, toDate: action.payload };
    case "RESET_FORM_DATE":
      return initialState;
    default:
      return state;
  }
};

export default dateReducer;
