import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const messaging = getMessaging();

const fetchWithRetry = (url, method, retries) => {
  return fetch(url, {
    method,
  }).then((response) => {
    if (response.ok) {
      return response.json()
    }

    if (retries > 0) {
      return fetchWithRetry(url, method, retries-1)
    }
  }).catch(error => console.error(error.message))
}

export const subscribeToTopic = async () => {
  const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

  await navigator.serviceWorker.ready

  getToken(messaging, {serviceWorkerRegistration: registration, vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY}).then(async (currentToken) => {
    if (currentToken) {
      const topics = process.env.REACT_APP_NOTIFICATION_WAKEUP_TOPIC.split(',')

      return Promise.all(topics.map(topic => fetchWithRetry(`${process.env.REACT_APP_SERVICE_URL}/api/notifications/firebase/topic/${topic}/token/${currentToken}`, 'POST', 3)))
    }

  })
}

export const checkNotification = async (tenant, userToken) => {
  const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

  await navigator.serviceWorker.ready

  getToken(messaging, {serviceWorkerRegistration: registration, vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY}).then(async (currentToken) => {
    if (currentToken) {
      return fetch(`${process.env.REACT_APP_SERVICE_URL}/api/user/check-notification?types=${process.env.REACT_APP_NOTIFICATION_TOPIC}`, {
        method: 'GET',
        headers: {
          "tenant": tenant,
          "firebasetoken": currentToken,
          "Authorization": `Bearer ${userToken}`,
          "Access-Control-Allow-Origin": "*",
        }
      })
    }
  })
}

export const onMessageListener = (handler) =>
    onMessage(messaging, (payload) => {
      handler(payload);
    });