import { useState } from "react";
import IconSelector from "../../IconSelector";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as S from "./SelectExport.styled";

function SelectExport({ exportOptions }) {
  const { t } = useTranslation();
  const [menuRef, setMenuRef] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setMenuRef(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuRef(null);
  };

  const onDownload = (url) => {
    try {
      const link = document.createElement("a");
      link.download = url;
      link.href = url;

      link.click();
    } catch (error) {
      setIsErrorModalOpen(true);
    }

    setMenuRef(null);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const hasExportOptionsWithValue = exportOptions.some(
    (option) => option.value
  );

  return hasExportOptionsWithValue ? (
    <div>
      <S.StyledButton onClick={handleOpenMenu}>
        {t("headerTableBtn.export")}
        <IconSelector svg="DownArrow" size={20} />
      </S.StyledButton>
      <S.StyledMenu
        anchorEl={menuRef}
        open={Boolean(menuRef)}
        onClose={handleCloseMenu}
      >
        {exportOptions.map((option) => (
          <S.StyledMenuItem
            key={option.label}
            onClick={() => onDownload(option.value)}
            sx={{
              width: "13.7rem",
              height: "3.2rem",
              justifyContent: "center",
              fontSize: "1.4rem",
            }}
          >
            {option.label}
          </S.StyledMenuItem>
        ))}
      </S.StyledMenu>
      <Dialog
        open={isErrorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-dialog-title"
      >
        <DialogTitle id="success-dialog-title">
          <IconButton
            aria-label="Fechar"
            onClick={handleErrorModalClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <IconSelector svg="CloseLine" />
          </IconButton>
          <IconSelector svg="ErrorExport" />
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="dialog-typography-title">
            {t("headerTableBtn.modal.exportError")}
          </Typography>
          <Typography className="dialog-typography-subTitle">
            {t("headerTableBtn.modal.exportErrorMsg")}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
}

export default SelectExport;
