import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";
import PermissionsControl from "../PermissionsControl";
import { HeaderRainReportTable } from "./header-rain-report-table/HeaderRainReportTable";
import { RainReportConfig } from "../../data/RainReportConfig/rainReportConfig";

function RainReportTable() {
  const { t } = useTranslation();
  const [searchOptions, setSearchOptions] = useState({
    dateStart: "",
    dateEnd: "",
    localIds: [],
    occurrences: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = t("rainReport.pageTitle.table");
  }, [t]);


  const buildRequestParams = (data, search, tableParams) => {
    const { dateStart, dateEnd, localIds, occurrences } = searchOptions;

    let options = {
      page: tableParams.page,
      perPage: tableParams.perPage,
      sortBy: tableParams.sortBy,
      order: tableParams.order,
      search: [],
    };

    if (dateStart && dateEnd) {
      options.date_start = dateStart;
      options.date_end = dateEnd;
    }
    if (localIds.length > 0) {
      options.local_ids = localIds;
    }

    if (occurrences.length > 0) {
      options.occurrences = occurrences;
    }

    let requestParams = {
      options: options,
      requestUrl: RainReportConfig.requestUrls.post,
      tableType: "rain_report",
      requestType: "post",
      ...(data.signal && { signal: data.signal }),
    };

    return requestParams;
  };

  return (
    <div className="c-main-content c-main-content--table">
      <PermissionsControl
        role={RainReportConfig.permission}
        type="componentPage"
        action="read"
      >
        <CustomTable
          params={{
            tableType: "rainReport",
            configTable: RainReportConfig,
            HeaderRoot: HeaderRainReportTable,
            buildRequestParams: buildRequestParams,
            redirectEditOnClick: false,
            searchOptions: searchOptions,
            setSearchOptions: setSearchOptions,
            setIsLoading: setIsLoading,
            isLoading: isLoading,
          }}
        />
      </PermissionsControl>
    </div>
  );
}

export default RainReportTable;
