import * as S from "./Switch.styled";

const label = { inputProps: { "aria-label": "Switch demo" } };

export const Switch = ({ id, handleSelectedOccurrences, disable }) => {
  const handleChange = (event) => {
    handleSelectedOccurrences(id);
  };
  return (
    <S.CustomSwitch
      {...label}
      onChange={handleChange}
      disabled={disable}
    />
  );
};
