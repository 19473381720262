import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconSelector from "../IconSelector";
import { useTranslation } from "react-i18next";

export const AlertEditButton = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditBtn = () => {
    navigate(`edit/${id}`);
  };

  return (
    <Button className="btn-table-edit" onClick={handleEditBtn}>
      <IconSelector svg="Edit" classname="icon--edit-btn" />
      <p className="edit-btn-text">{t("common.table.action-btn.edit")}</p>
    </Button>
  );
};
