import { SET_AREA_VALIDATION, SET_INTERSECTION_VALIDATION  } from "../actions/formValidation";

const initialState = {
  isInsideBoundary: true,
  hasIntersection: false,
}

export default function formValidation(state=initialState,action) {
  switch(action.type) {
    case SET_AREA_VALIDATION:
      return {
        ...state,
        isInsideBoundary: action.isInsideBoundary,
      }
    case SET_INTERSECTION_VALIDATION:
      return {
        ...state,
        hasIntersection: action.hasIntersection,
      }
    default:
      return state
  }
}